import { useParams, Link } from 'react-router-dom';
import config from '../config';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { Blobs, AnimRandomText, AnimTranslateText, ExternalLink } from '../components';
import {
    PageSplitPart,
    Marge,
    ProjectTitle,
    ProjectInfo,
    ProjectDate,
    Paragraph,
    ButtonLink
} from '../components/Styled';

const PageSplitPartInfo = styled(PageSplitPart)`
    position: fixed;
    top: 0px;
    height: 100vh;

    @media (max-width: 970px) {
        position: relative;
        padding: 15px;
        width: calc(100vw - 30px);
        height: auto;
    }
`

const SpecialMobileContainer = styled(PageSplitPart)`
    width: 100vw;
`

const InfoContainer = styled.div`
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 30px;
    width: 40vw;
    margin-top: -15px;

    @media (max-width: 970px) {
        width: 100%;
        height: auto;
        margin-top: 0px;
    }
`

const BlobsContainer = styled.div`
    top: 0px;
    width: 500px;
    position: fixed;
    height: 100vh;
    left: 0vw;
    z-index: 20;
    pointer-events: none;
    mix-blend-mode: darken;
    @media (max-width: 970px) {
        position: absolute;
        top: auto;
        width: auto;
        height: auto;
        margin-top: -75px;
        margin-left: -15px;
    }
`

const BlobsBox = styled.div `
    height: 100vw;
    width: 60px;
    transform-origin: 50% right;
    transform: rotate(-90deg) translate(calc(50vw - 50%), calc(50vw - 30px));
`

const ButtonLine = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 60px;

    @media (max-width: 970px) {
        gap: 15px;
    }

    &.inContent {
        width: 90%;
    }
`

const Summary = styled(Paragraph)`
    font-size: 15px;
    padding: 15px 0px 0px 0px;
    border-top: 1px solid #1F1F1F;
    text-align: justify;

    @media (max-width: 970px) {
        margin-top: 30px;
    }
`

const LinkContainer = styled.div `
    display: flex;
    gap: 5px 15px;
    font-style: italic;
    flex-wrap: wrap;
    text-decoration: underline;
`

const PageSplitPartContent = styled(PageSplitPart)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 50vw;

    @media (max-width: 970px) {
        margin-left: 0;
        border-top: 1px solid #1F1F1F;
        margin-top: 45px;
        padding-bottom: 60px;
    }
`

const ProjectCover = styled.img `
    height: 450px;
    width: 100%;
    object-fit: cover;

    @media (max-width: 970px) {
        height: 210px;
    }
`

const ContentContainer = styled.div`
  padding: 30px 0px 90px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  @media (max-width: 970px) {
    padding: 60px 0px 30px 0px;
  }
`;

const ContentText = styled.p`
  font-size: 15px;
  padding: 0px 30px 0px 30px;
  text-align: justify;

  line-height: 140%;

  @media (max-width: 970px) {
    padding: 0px 15px 0px 15px;
  }

  & > a {
    text-decoration: underline;
  }
`;

const ContentImage = styled.img`
    width: 100%;
`

const ContentVideo = styled.video`
    width: 100%;
`

const ContentYoutube = styled.div`
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;

    & > iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
`

const ContentFlex = styled.div`
    width: 100%;
    display: flex;
    gap: 15px;
    justify-content: flex-start;


    @media (max-width: 1400px) {
        flex-direction: column;
    }
`

const ContentFlexBox = styled.div`
    flex: 1;

    line-height: 0;
`

const ButtonLinkBack = styled(Link)`
    font-size: 15px;
    font-family: "IBM-Plex-Mono";
    font-weight: 600;
    font-style: italic;
    border: solid;
    border-width: 1px;
    user-select: none;

    opacity: 0.75;
    background-color: transparent;
    color: #1F1F1F;
    border-color: #1F1F1F;

    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
    display: flex;
    gap: 7px;

    border: 0px;
    margin-bottom: -21px;
    padding: 15px;
    margin-left: -15px;

    @media (min-width: 971px) {
        &:hover {
            opacity: 1;
            transform: translateX(-10px);
            & > p {
                text-decoration: underline;
            }
        }
    }

    @media (max-width: 970px) {
        opacity: 0.75;
        position: absolute;
        margin-top: 15px;
        margin-left: 15px;
        color: #F4F3F1;
        border-radius: 30px;
        background-color: #1F1F1F;
    }
`

function findPositionByUrl(arr, targetUrl) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].url === targetUrl) {
        return i;
      }
    }
    return -1;
  }

const ProjectPage = (data) => {
    const [Width, setWidth] = useState(window.innerWidth);
    const { projectURL } = useParams();
    const id = findPositionByUrl(config.projects, projectURL)
    data.stats.order = id % 2;

    const prevProject = id - 1 < 0 ? config.projects.length - 1 : id - 1
    const nextProject = id + 1 >= config.projects.length ? 0 : id + 1

    const updateDimensions = () => {
        const width = window.innerWidth
        setWidth(width)
    }

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    });

    return( Width > 970 ? ProjectPageBrowser(data, prevProject, nextProject) : ProjectPageMobile(data, prevProject, nextProject))
}

const ProjectPageBrowser = (data, prevProject, nextProject) => {
    return (
        <div>
            <Marge/>
            <BlobsContainer>
                <Blobs stats={data.stats} />
            </BlobsContainer>

            <div>
                <PageSplitPartInfo>
                    <InfoContainer>
                        <ButtonLinkBack to={"/projects"}> <div>{"<"}</div> <p>Back</p> </ButtonLinkBack>
                        <ProjectTitle>
                            {data.title.map((title, index) => <AnimTranslateText key={index} speed={0.5} text={title}/>)}
                        </ProjectTitle>
                        <ProjectInfo>
                            <div>{data.filtres.join(' - ')}</div>
                            <ProjectDate>{data.date}</ProjectDate>
                        </ProjectInfo>
                        <Summary>
                            <AnimRandomText speed={25} increment={10} text={data.summary} />
                        </Summary>
                        {data.links ?
                            <LinkContainer>
                                {data.links.map((link, index) => <ExternalLink key={index} url={link.url} name={link.name}/>)}
                            </LinkContainer>
                            :
                            null
                        }
                        <ButtonLine>
                            <ButtonLink to={"/projects/" + config.projects[prevProject].url}> <div>{"<-"}</div> <p>{config.projects[prevProject].title.join(' ')}</p> </ButtonLink>
                            <ButtonLink to={"/projects/" + config.projects[nextProject].url}> <p>{config.projects[nextProject].title.join(' ')}</p> <div>{"->"}</div> </ButtonLink>
                        </ButtonLine>
                    </InfoContainer>
                </PageSplitPartInfo>
                <PageSplitPartContent style={{ backgroundColor: data.color || 'transparent', color: data.textColor || '#1F1F1F' }}>
                    <ProjectCover src={data.cover} alt={data.title + " project cover"}/>
                    <Content data={data}/>
                </PageSplitPartContent>
            </div>
        </div>
    )
}

const ProjectPageMobile = (data, prevProject, nextProject) => {
    return (
        <div>
            <ButtonLinkBack to={"/projects"}> <div>{"<"}</div> <p>Back</p> </ButtonLinkBack>
            <SpecialMobileContainer>
                <ProjectCover src={data.cover} alt={data.title + " project cover"}/>
                <PageSplitPartInfo>
                    <InfoContainer>
                        <ProjectTitle>
                            {data.title.map((title, index) => <AnimTranslateText key={index} speed={0.5} text={title}/>)}
                        </ProjectTitle>
                        <ProjectInfo>
                            <div>{data.filtres.join(' - ')}</div>
                            <ProjectDate>{data.date}</ProjectDate>
                        </ProjectInfo>
                        <Summary>
                            <BlobsContainer>
                                <BlobsBox>
                                    <Blobs  stats={data.stats}/>
                                </BlobsBox>
                            </BlobsContainer>
                            <AnimRandomText speed={25} increment={10} text={data.summary} />
                        </Summary>
                        {data.links ?
                            <LinkContainer>
                                {data.links.map((link, index) => <ExternalLink key={index} url={link.url} name={link.name}/>)}
                            </LinkContainer>
                            :
                            null
                        }
                        <ButtonLine>
                            <ButtonLink to={"/projects/" + config.projects[prevProject].url}> <div>{"<-"}</div> <p>{config.projects[prevProject].title.join(' ')}</p> </ButtonLink>
                            <ButtonLink to={"/projects/" + config.projects[nextProject].url}> <p>{config.projects[nextProject].title.join(' ')}</p> <div>{"->"}</div> </ButtonLink>
                        </ButtonLine>
                    </InfoContainer>
                </PageSplitPartInfo>
            </SpecialMobileContainer>
            <PageSplitPartContent style={{ backgroundColor: data.color || 'transparent', color: data.textColor || '#1F1F1F' }}>
                <Content data={data}/>
                <ButtonLine className='inContent'>
                    <ButtonLink to={"/projects/" + config.projects[prevProject].url}> <div>{"<-"}</div> <p>{config.projects[prevProject].title.join(' ')}</p> </ButtonLink>
                    <ButtonLink to={"/projects/" + config.projects[nextProject].url}> <p>{config.projects[nextProject].title.join(' ')}</p> <div>{"->"}</div> </ButtonLink>
                </ButtonLine>
            </PageSplitPartContent>
        </div>
    )
}

const ContentGeneration = (item, index) => {
    switch (item.type) {
        case "text":
            return (<ContentText key={index} dangerouslySetInnerHTML={{__html: item.html}}  />);
        case "image":
            return (<ContentImage src={item.src} key={index} alt={item.alt}/>);
        case "video":
            return (<ContentVideo key={index} src={item.src} type="video/webm" autoPlay muted loop />);
        case "split":
            return (
                <ContentFlex key={index}>
                    {item.content.map((element, i) =>
                        <ContentFlexBox key={i}>
                            {ContentGeneration(element, 0)}
                        </ContentFlexBox>
                    )}
                </ContentFlex>
            );
        case "youtube":
            return (<ContentYoutube key={index} dangerouslySetInnerHTML={{__html: item.html}} />);
        case "vimeo":
            return (<div key={index} style={{width: '100%'}}><div dangerouslySetInnerHTML={{__html: item.html}} /></div>);
        default :
            return (null);
    }
}

const Content = ({ data }) => {
    return (
        <ContentContainer>
            {data.content.map( (item, index) => ContentGeneration(item, index) )}
        </ContentContainer>
    )
}

const ProjectTemplate = () => {
    const { projectURL } = useParams();
    let page = config.projects.find(item => item.url === projectURL);

    if (!page) {
        window.location.href = "/404";
    }

    return ProjectPage(page);
}

export default ProjectTemplate;
