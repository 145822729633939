import styled from 'styled-components';
import { Link, useLocation } from "react-router-dom";
import ExternalLink from './ExternalLink';
import { useState, useEffect } from 'react';

const Container = styled.div `
    background-color: #1F1F1F;
    color: #F4F3F1;
    z-index: 1000;
    font-size: 15px;
    margin-top: 120px;
    position: relative;
    flex-grow: 1;

    @media (max-width: 970px) {
        margin-top: 60px;
    }
`

const ContactContainer = styled.div `
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 970px) {
        flex-direction: column;
        gap: 30px;
        padding-bottom: 60px;
    }
`

const Logo = styled.svg `
    padding: 60px 6.5vw;
    min-width: 125px;
    min-height: 125px;

    @media (max-width: 970px) {
        min-width: 80px;
        min-height: 80px;
    }
`

const LogoContact = styled.div `
    display: flex;
    align-items: center;

    @media (max-width: 970px) {
        flex-direction: column;
    }
`

const ColumnText = styled.div `
    display: flex;
    flex-direction: column;
`

const ColumnTextCenter = styled(ColumnText) `
    align-items: center;
    justify-content: center;
    gap: 10px;
`

const Social = styled(ColumnText) `
    padding-right: 6.5vw;
    justify-content: center;
    gap: 5px;

    @media (max-width: 970px) {
        display: none;
    }
`

const LinkCustom = styled(Link) `
    &:focus,
    &:hover {
        text-decoration: underline;
    }
`

const Footer = () => {
    const [Width, setWidth] = useState(window.innerWidth);
    const location = useLocation().pathname.split('/')

    const updateDimensions = () => {
        const width = window.innerWidth
        setWidth(width)
    }

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    });

    if (location.length > 2 && location[1] === "projects") {return(null)}

    return (
      <Container>
        <ContactContainer>
            <LogoContact>
                <Logo viewBox="0 0 152 152" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M151.322 86.0157L136.242 29.6176C134.397 22.7417 129.019 17.3761 122.154 15.5479L66.0591 0.663374C59.2531 -1.14502 51.9908 0.802481 46.9905 5.79048L5.85703 46.7477C0.817043 51.7754 -1.1672 59.1084 0.678146 65.9843L15.7584 122.382C17.6038 129.258 22.9811 134.624 29.8466 136.452L85.9214 151.337C92.7274 153.145 99.9897 151.198 104.99 146.21L146.123 105.252C151.183 100.225 153.168 92.8916 151.322 86.0157ZM133.107 97.3033L100.228 88.5793C99.7714 88.4601 99.3151 88.3209 98.8785 88.2017L73.5794 113.519C63.8962 123.217 48.181 123.217 38.4979 113.519C28.8148 103.821 28.8148 88.0825 38.4979 78.3847L63.9955 52.8684C63.9359 52.6299 63.8566 52.4114 63.797 52.1729L54.8878 18.8865C52.9234 11.5138 59.65 4.75711 67.0115 6.70461L123.086 21.5891C126.519 22.5032 129.218 25.186 130.13 28.624L145.25 85.1215C147.215 92.4942 140.488 99.2508 133.107 97.3033Z" fill="#F4F3F1"/>
                    <path d="M122.69 29.2207C113.007 19.5229 97.2913 19.5229 87.6082 29.2207L63.9956 52.869C68.7975 69.8798 81.9927 83.1944 98.8787 88.1824L122.69 64.3354C132.373 54.6376 132.373 38.9185 122.69 29.2207Z" fill="#F4F3F1"/>
                </Logo>
                { Width > 970 ?
                    <ColumnText>@website:~$ madeBy --get<br/><br/>Quentin JEANNINGROS<br/>aka Grou_Grou_</ColumnText>
                    :
                    <ColumnText>Made by Quentin JEANNINGROS</ColumnText>
                }
            </LogoContact>
            <ColumnTextCenter>
                How I made this Website ?
                <LinkCustom to="/how">-&gt; Discover &lt;-</LinkCustom>
            </ColumnTextCenter>
            <Social>
                <ExternalLink url="https://www.instagram.com/quentinjeanningros/" name="Instagram" fill="#F4F3F1" />
                <ExternalLink url="https://www.behance.net/quentinjeanningros" name="Behance" fill="#F4F3F1" />
                <ExternalLink url="https://github.com/quentinjeanningros" name="Github" fill="#F4F3F1" />
                <ExternalLink url="https://www.linkedin.com/in/quentin-jeanningros" name="Linkedin" fill="#F4F3F1" />
            </Social>
        </ContactContainer>
      </Container>
    );
  }

export default Footer;