const config = {
  projects: [

    {
      url: "exploring_historical_campaigns",
      title: ["Exploring", "Historical", "Campaigns"],
      color: "#020202",
      textColor: "#DDDCCC",
      cover: "/images/project/rivenzi-maps/Cover.webp",
      filtres: ["3D", "Branding", "Documentary"],
      date: "March 2024",
      stats: { des: 35, mod: 65, dev: 0 },

      links: [
        {
          name: "More on Behance",
          url: "https://www.behance.net/gallery/197620015/Exploring-Historical-Campaigns-3D-Maps",
        },
        {
          name: "Rivenzi's Youtube channel",
          url: "https://www.youtube.com/@Rivenzi_"
        },
        {
          name: "Video Editor Michael (Selgy) Ducatez",
          url: "https://www.youtube.com/c/Selgy"
        },
        {
          name: "Cinematography by Alex Arnaud",
          url: "https://twitter.com/studiogachette"
        },
        {
          name: "Photography by  Mathieu Lacout",
          url: "https://www.instagram.com/mthlct/"
        },
        {
          name: "Sourcing by Thomas Quinquis",
          url: "https://www.instagram.com/mthlct/"
        }
      ],
      summary: `Rivenzi, a Twitch streamer, known for his passion for sports, history, and video games, transitioned from live analysis to producing his own documentaries. His latest projects delve into the SS Dirlewanger Brigade and the soldiers of Napoleon's era.`,

      content: [
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/941898671?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: "text",
          html: `Rivenzi, a Twitch streamer, known for his passion for sports, history, and video games, transitioned from live analysis to producing his own documentaries. His latest projects delve into the SS Dirlewanger Brigade and the soldiers of Napoleon's era.`,
        },
        {
          type: "image",
          src: `/images/project/rivenzi-maps/1.webp`,
          alt: "3D map and photo of Rivenzi",
        },
        {
          type: "text",
          html: `<br/><br/>THE REQUEST`,
        },
        {
          type: "text",
          html: `<br/>Rivenzi approached me following my work on Simon Puech's maps. He tasked me with illustrating the territorial conquests of Nazi Germany towards Eastern Europe, Napoleon's domains, and the Battle of Wagram.`,
        },
        {
          type: "text",
          html: `<br/><br/>INSPIRATION`,
        },
        {
          type: "text",
          html: `<br/>During our brainstorming sessions, we considered various approaches. Initially, we contemplated detailed maps with an aged paper effect.`,
        },
        {
          type: "text",
          html: `However, given the fast-paced nature of YouTube videos where illustrations change rapidly, we opted for minimalistic maps with concise text to ensure viewer engagement.`,
        },
        {
          type: "image",
          src: `/images/project/rivenzi-maps/2.webp`,
          alt: "Mood board of maps",
        },
        {
          type: "text",
          html: `<br/><br/>WORKFLOW`,
        },
        {
          type: "text",
          html: `<br/>After several tests, I developed a workflow to create precise maps.`,
        },
        {
          type: "text",
          html: `Topographical information was sourced from the heightmapper of the Tangram project by Mapzen, an open-source initiative under the Linux Foundation.`,
        },
        {
          type: "text",
          html: `Elevations were represented subtly, with lighter shades denoting higher altitudes. Borders were defined using satellite images, Google Maps, books, and online sources.`,
        },
        {
          type: "image",
          src: `/images/project/rivenzi-maps/3.webp`,
          alt: "Workflow to create 3D maps",
        },
        {
          type: "image",
          src: `/images/project/rivenzi-maps/4.webp`,
          alt: "Section title with photos of Rivenzi",
        },
        {
          type: "text",
          html: `<br/><br/>I. THE SS DIRLEWANGER BRIGADE`,
        },
        {
          type: "image",
          src: `/images/project/rivenzi-maps/5.webp`,
          alt: "Photo of Rivenzi in Berlin",
        },
        {
          type: "text",
          html: `<br/>.001 HISTORICAL REFERENCES`,
        },
        {
          type: "text",
          html: `This segment focuses on German advancements eastward and the resistance encountered. Syncing pocket appearances and closures with the German advance required cross-referencing multiple sources.`,
        },
        {
          type: "image",
          src: `/images/project/rivenzi-maps/6.webp`,
          alt: "Maps and historical references to create 3D maps border",
        },
        {
          type: "text",
          html: `<br/>.002 RECONSTRUCTION`,
        },
        {
          type: "text",
          html: `Days were spent reconstructing period borders, adjusting relief heights for clarity.`,
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/rivenzi-maps/Gif 1.gif",
              alt: `Height map of europe`,
            },
            {
              type: "image",
              src: "/images/project/rivenzi-maps/Gif 2.gif",
              alt: `Border of Napoleon's empire`,
            },
          ],
        },
        {
          type: "text",
          html: `<br/>.003 MOTION`,
        },
        {
          type: "text",
          html: `Smooth camera movements accompany German progressions, ensuring fluid pacing with the video.`,
        },
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/941898630?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: "text",
          html: `<br/><br/>I. THE SS DIRLEWANGER BRIGADE`,
        },
        {
          type: "image",
          src: `/images/project/rivenzi-maps/7.webp`,
          alt: "A photo of Rivenzi at a Napoleon Battle reconstruction",
        },
        {
          type: "text",
          html: `<br/>.001 HISTORICAL REFERENCES`,
        },
        {
          type: "text",
          html: `This documentary follows Napoleon's soldiers during the Austrian Empire conquest and describes the Battle of Wagram.`,
        },
        {
          type: "text",
          html: `Borders were referenced from "Atlas de l'histoire de France" by Belin, while multiple sources informed the Battle of Wagram development.`,
        },
        {
          type: "image",
          src: `/images/project/rivenzi-maps/8.webp`,
          alt: "Maps used to recreate wagram battle and border during Napoleon's era",
        },
        {
          type: "text",
          html: `<br/>.002 RECONSTITUTION`,
        },
        {
          type: "text",
          html: `Photoshop was utilized to remove human terraforming and buildings for Wagram, redrawing the Danube's original shape by hand.`,
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/rivenzi-maps/Gif 3.gif",
              alt: `Border of Napoleon's empire`,
            },
            {
              type: "image",
              src: "/images/project/rivenzi-maps/Gif 4.gif",
              alt: `Wagram height map`,
            },
          ],
        },
        {
          type: "text",
          html: `<br/>.003 MOTION & RECONSTITUTION`,
        },
        {
          type: "text",
          html: `Camera movements and motion design adapt to the voiceover descriptions, enhancing narrative cohesion.`,
        },
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/941898646?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: "text",
          html: `<br/><br/>CONCLUSION`,
        },
        {
          type: "text",
          html: `<br/>These projects represent a fusion of historical research and creative design. By meticulously reconstructing historical landscapes and battles, we aim to immerse viewers in the rich tapestry of Rivenzi's documentaries. Through effective collaboration and innovative techniques, we've brought historical events to life in a visually engaging and informative manner.`,
        },
        {
          type: `youtube`,
          html: `<iframe width="1280" height="720" src="https://www.youtube.com/embed/Lt15HogJhks?rel=0&showinfo=0&modestbranding=1" title="YouTube video player" frameborder="0"; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />`,
        },
        {
          type: `youtube`,
          html: `<iframe width="1280" height="720" src="https://www.youtube.com/embed/G2Ycs-h9hdQ?rel=0&showinfo=0&modestbranding=1" title="YouTube video player" frameborder="0"; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />`,
        },
      ]
    },

    {
      url: "luxurious_underground_complex",
      title: ["Luxurious", "Underground", "Complex"],
      color: "#010101",
      textColor: "#D4DCCF",
      cover: "/images/project/simon-bunker/Cover.webp",
      filtres: ["3D", "Branding", "Documentary"],
      date: "March 2024",
      stats: {
        des: 35, mod: 65, dev: 0
      },

      links: [
        {

          name: "More on Behance",
          url: "https://www.behance.net/gallery/196026595/Survival-Condo-the-Luxurious-Underground-Complex",
        },
        {

          name: "Simon's Youtube channel",
          url: "https://www.youtube.com/@SPuech"
        }

      ],
      summary: `In a world witnessing escalating conflicts, intensifying war climates, and multiplying natural disasters, a new business emerges: luxury bunkers. Among them stands the Survival Condo, a hyper-secure apartment and leisure complex nestled within a former Atlas missile silo.`,

      content: [
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/933461297?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: "text",
          html: `In a world witnessing escalating conflicts, intensifying war climates, and multiplying natural disasters, a new business emerges: luxury bunkers. Among them stands the Survival Condo, a hyper-secure apartment and leisure complex nestled within a former Atlas missile silo.`,
        },
        {
          type: "image",
          src: `/images/project/simon-bunker/1.webp`,
          alt: "Wallpaper of the project showing the logo of Simon and the model of the survival Condo",
        },
        {
          type: "text",
          html: `Simon Puech, a content creator specializing in geopolitical themes, ventured to this colossal complex located in the heart of the United States, Kansas, for his latest documentary. Here, he interviewed its creator, Laurence "Larry" A. Hall.`,
        },
        {
          type: "image",
          src: `/images/project/simon-bunker/2.webp`,
          alt: "Multiple photos from the documentary, one of Simon, another of a security man and the US map",
        },
        {
          type: "text",
          html: `<br/><br/>01 • THE REQUEST`,
        },
        {
          type: "text",
          html: `<br/>Within this framework, Simon tasked me with creating a 3D model of the Survival Condo to feature in his video. The objective was to highlight the areas described in the documentary, considering the challenging underground and compact nature of the location.`,
        },
        {
          type: "text",
          html: `<br/><br/>02  •  INSPIRATION & BRANDING`,
        },
        {
          type: "text",
          html: `<br/>Simon's aesthetic revolves around minimalism and black-and-white tones. For this project, I drew inspiration from a screen system featured in the work of the studio Tendril for Hublot. The model's reliability stemmed from a blend of plans provided by Larry and Simon's firsthand accounts.`,
        },
        {
          type: "image",
          src: `/images/project/simon-bunker/3.webp`,
          alt: "References of the Hublo studio tendril project and the blueprint of the survival condo",
        },
        {
          type: "text",
          html: `<br/><br/>03  •  DESIGN CHALLENGES`,
        },
        {
          type: "text",
          html: `<br/>The bunker is an impressive yet intricate space, challenging to represent clearly in a model. Providing an exterior perspective of an underground structure proved daunting, especially given its compact layout.`,
        },
        {
          type: "image",
          src: `/images/project/simon-bunker/4.webp`,
          alt: "The timeline of the project. separed in 3 weeks",
        },
        {
          type: "text",
          html: `Therefore, I chose to depict the rooms through screens rather than fully recreating them in 3D. This decision not only streamlined the process but also adhered to the project's tight two-week timeline.`,
        },
        {
          type: "image",
          src: `/images/project/simon-bunker/5.webp`,
          alt: "blueprint of each floor made on figma",
        },
        {
          type: "text",
          html: `<br/><br/>04  •  DESIGN SYSTEM`,
        },
        {
          type: "text",
          html: `<br/>The UI displayed on the screens was crafted using Figma and After Effects. I developed a mini design system to describe the various rooms.`,
        },
        {
          type: "image",
          src: `/images/project/simon-bunker/6.webp`,
          alt: "Design system of floor UI",
        },
        {
          type: "text",
          html: `<br/>Therefore, I chose to depict the rooms through screens rather than fully recreating them in 3D. This decision not only streamlined the process but also adhered to the project's tight two-week timeline.<br/>`,
        },
        {
          type: "image",
          src: `/images/project/simon-bunker/7.webp`,
          alt: "Levels and gradient of color for each floors",
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/simon-bunker/8.1.webp",
              alt: `Bottom`,
            },
            {
              type: "image",
              src: "/images/project/simon-bunker/8.2.webp",
              alt: `Top`,
            },
          ],
        },
        {
          type: "text",
          html: `<br/><br/>05  •  3D MOTION`,
        },
        {
          type: "text",
          html: `<br/>The 3D architectural structure was designed to closely resemble reality. The dome layers were stylized and capable of rotating around the structure to simulate the confined atmosphere of the rooms.`,
        },
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/934486686?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: "text",
          html: `<br/>The 3D architectural structure was designed to closely resemble reality. The dome layers were stylized and capable of rotating around the structure to simulate the confined atmosphere of the rooms.`,
        },
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/934487890?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: "text",
          html: `<br/><br/>06  • FRAMES & VIDEO`,
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/simon-bunker/11.1.webp",
              alt: `USA map`,
            },
            {
              type: "image",
              src: "/images/project/simon-bunker/11.2.webp",
              alt: `Top`,
            },
          ],
        },
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/934495234?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/simon-bunker/13.1.webp",
              alt: `View of all floors`,
            },
            {
              type: "image",
              src: "/images/project/simon-bunker/13.2.webp",
              alt: `Middle level`,
            },
            {
              type: "image",
              src: "/images/project/simon-bunker/13.3.webp",
              alt: `Extention`,
            },
          ],
        },
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/934491326?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/simon-bunker/15.1.webp",
              alt: `View of a red floor`,
            },
            {
              type: "image",
              src: "/images/project/simon-bunker/15.2.webp",
              alt: `View of a red floor`,
            },
          ],
        },
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/934490907?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: "text",
          html: `<br/><br/>07  •  CONCLUSION`,
        },
        {
          type: "text",
          html: `<br/>This project offers a captivating glimpse into the intricate world of luxury underground bunkers. Through meticulous 3D modeling and dynamic motion design, we've brought the Survival Condo to life, providing viewers with a comprehensive understanding of its layout and features. `,
        },
        {
          type: "text",
          html: `<br/>Despite the challenges posed by depicting an underground space, our innovative approach ensures a visually compelling and informative experience for audiences engaging with Simon Puech's documentary.`,
        },
        {
          type: `youtube`,
          html: `<iframe width="1280" height="720" src="https://www.youtube.com/embed/WqnKjcKRQ4E?rel=0&showinfo=0&modestbranding=1" title="YouTube video player" frameborder="0"; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />`,
        },
      ]
    },

    {

      url: "reconstructions_11_million_heist",
      title: ["Reconstructions of", "an €11 Million", "Heist"],
      color: "#CED0C7",
      textColor: "#292B27",
      cover: "/images/project/simon-tony/Cover.webp",
      filtres: ["3D", "Branding", "Documentary"],
      date: "March 2024",
      stats: { des: 35, mod: 65, dev: 0 },

      links: [
        {
          name: "More on Behance",
          url: "https://www.behance.net/gallery/195451919/Unicolor-Reconstructions-of-an-11-Million-Heist",
        },
        {
          name: "Simon's Youtube channel",
          url: "https://www.youtube.com/@SPuech"
        }
      ],
      summary: `In November 2009, armored truck driver Tony Musulin vanished along with €11 million. Fifteen years later, Simon Puech, a French content creator known for his work on geopolitical and societal issues, seeks to unravel this mystery. Simon's project not only delves into the intriguing journey of Musulin but also highlights the demanding work conditions within this high-stakes profession.`,

      content: [
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/930406388?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: "image",
          src: `/images/project/simon-tony/3.webp`,
          alt: "Simon Puech Logo",
        },
        {
          type: "image",
          src: `/images/project/simon-tony/1.webp`,
          alt: "2 photos of Simon Puech",
        },
        {
          type: "text",
          html: `<br/><br/>01 • THE REQUEST`,
        },
        {
          type: "text",
          html: `<br/>In November 2009, armored truck driver Tony Musulin vanished along with €11 million. Fifteen years later, Simon Puech, a French content creator known for his work on geopolitical and societal issues, seeks to unravel this mystery. Simon's project not only delves into the intriguing journey of Musulin but also highlights the demanding work conditions within this high-stakes profession.`,
        },
        {
          type: "text",
          html: `<br/><br/>02  •  INSPIRATION & BRANDING`,
        },
        {
          type: "text",
          html: `<br/>Simon's content straddles the realms of brutalism and minimalism. This required a harmonious balance of realism, graphical styling, and narrative coherence in the visual representation of Musulin's story.`,
        },
        {
          type: "image",
          src: `/images/project/simon-tony/4.webp`,
          alt: "the moodboard of the project",
        },
        {
          type: "text",
          html: `<br/><br/>03  •  DESIGN CHALLENGES`,
        },
        {
          type: "text",
          html: `<br/>The project faced a plethora of challenges and constraints : deadline, assets, texturing and rhythm.`,
        },
        {
          type: "text",
          html: `<br/>TIME CONSTRAINT`,
        },
        {
          type: "text",
          html: `<br/>The video was scheduled for release in two weeks, imposing a tight deadline.​​​​​`,
        },
        {
          type: "image",
          src: `/images/project/simon-tony/5.webp`,
          alt: "The road map of the project",
        },
        {
          type: "text",
          html: `<br/>VIDEO PACE ADAPTATION`,
        },
        {
          type: "text",
          html: `<br/>The animations needed to match the documentary's rhythm without disrupting its flow. I decide to use fade to make character move.​​​​​`,
        },
        {
          type: "image",
          src: `/images/project/simon-tony/5.5.gif`,
          alt: "A dummy crash test moving cash form a van to another",
        },
        {
          type: "text",
          html: `<br/>MONOCHROME ASSETS`,
        },
        {
          type: "text",
          html: `<br/>Achieving a minimalist aesthetic while creating non-existent, realistic monochrome assets (such as the armored van) required significant effort. To address these challenges, I opted for monochrome, highly textured realistic scenes.`,
        },
        {
          type: "image",
          src: `/images/project/simon-tony/6.webp`,
          alt: "Transformation of a color map to a unicolor map",
        },
        {
          type: "text",
          html: `<br/>CRASH TEST DUMMIES`,
        },
        {
          type: "text",
          html: `<br/>To address these challenges, I opted for monochrome, highly textured realistic scenes. Characters were represented by animated crash test dummies, blending seamlessly into the video's dynamic without detracting from the realism.`,
        },
        {
          type: "image",
          src: `/images/project/simon-tony/7.webp`,
          alt: "3D reconstruction of 2 dummy crash test waiting one with money, the other one with a gun",
        },
        {
          type: "text",
          html: `<br/><br/>05  •  RECONSTITUTION`,
        },
        {
          type: "text",
          html: `<br/>The initial step involved recreating the armored van without access to any blueprints, relying solely on photographs for accuracy.`,
        },
        {
          type: "image",
          src: `/images/project/simon-tony/8.webp`,
          alt: "3D modeling of a cash transfer van",
        },
        {
          type: "text",
          html: `The subsequent phase focused on reconstructing various environments. Some locations were directly sourced from Google Maps, while others were based on imagery from a movie telling the same story, released eleven years earlier.`,
        },
        {
          type: "image",
          src: `/images/project/simon-tony/9.webp`,
          alt: "3D reconstruction of real location used in the documentary",
        },
        {
          type: "text",
          html: `<br/><br/>07  •  CONCLUSION`,
        },
        {
          type: "text",
          html: `<br/>This project represecnts a meticulous blend of historical reconstruction and artistic interpretation. Through detailed 3D modeling and innovative design choices, we have brought to life the mysterious case of Tony Musulin and the €11 million heist.`,
        },
        {
          type: "text",
          html: `The use of monochrome scenes and crash test dummy characters not only adheres to Simon Puech's aesthetic but also provides a unique lens through which viewers can engage with this remarkable story. The challenges of creating realistic assets and adapting to the documentary's pace were met with creative solutions, resulting in a compelling and immersive narrative experience.`,
        },
        {
          type: `youtube`,
          html: `<iframe width="1280" height="720" src="https://www.youtube.com/embed/rpkAVRM_XmA?rel=0&showinfo=0&modestbranding=1" title="YouTube video player" frameborder="0"; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />`,
        },
      ]
    },

    {
      url: "3d_maps_polish_border",
      title: ["3D Maps for", "Polish Border", "Documentary"],
      color: "#D0D6CB",
      textColor: "#292B27",
      cover: "/images/project/simon-pologne/Cover.webp",
      filtres: ["3D", "Branding", "Documentary"],
      date: "February 2024",
      stats: { des: 35, mod: 65, dev: 0 },

      links: [
        {

          name: "More on Behance",
          url: "https://www.behance.net/gallery/195138511/3D-Maps-for-Documentary-on-the-Polish-Border",
        },
        {

          name: "Simon's Youtube channel",
          url: "https://www.youtube.com/@SPuech"
        }
      ],

      summary: `Simon Puech, a renowned French content creator specializing in geopolitical and societal themes. Following his impactful reports from war-torn regions in Ukraine and Afghanistan, Simon ventures to Poland, Europe's doorstep, to explore and document the treatment of migrants in the borderland between Poland and Belarus.`,

      content: [
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/929107022?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: "image",
          src: `/images/project/simon-pologne/2.webp`,
          alt: "Simon Puech Logo",
        },
        {
          type: "image",
          src: `/images/project/simon-pologne/1.webp`,
          alt: "Photos from Simon in Poland",
        },
        {
          type: "text",
          html: `<br/><br/>01 • THE REQUEST`,
        },
        {
          type: "text",
          html: `<br/>Simon approached me with a unique request: to craft 3D maps that would not only contextualize his journey and on-ground observations but also bolster the narrative of his documentary. The challenge was to design maps that enable viewers to trace Simon's path visually while supporting the documentary's thematic content.`,
        },
        {
          type: "text",
          html: `<br/><br/>02 • INSPIRATION & BRANDING`,
        },
        {
          type: "text",
          html: `<br/>Simon Puech's aesthetic can be described as a blend of brutalism and minimalism. Drawing from this, we collaboratively curated a set of references to guide my design process, aiming to reflect Simon's distinct visual style in the maps I created.`,
        },
        {
          type: "image",
          src: `/images/project/simon-pologne/3.webp`,
          alt: "References & moodboard",
        },
        {
          type: "text",
          html: `<br/><br/>03 • DESIGN CHALLENGES`,
        },
        {
          type: "text",
          html: `<br/>The project presented several challenges from the onset:`,
        },
        {
          type: "text",
          html: `1.How do we highlight necessary information for understanding while maintaining a minimalist style?`,
        },
        {
          type: "text",
          html: `2.How do we source reliable topological data?`,
        },
        {
          type: "text",
          html: `Our goal was to establish a streamlined process that could facilitate the generation of multiple maps without sacrificing quality or clarity.`,
        },
        {
          type: "image",
          src: `/images/project/simon-pologne/4.webp`,
          alt: "Workflow",
        },
        {
          type: "text",
          html: `<br/><br/>03.1  • EXPERIMENTATION`,
        },
        {
          type: "text",
          html: `<br/>Over two weeks of testing, we achieved a minimalist, monochromatic, and legible map format. Key decisions included:`,
        },
        {
          type: "text",
          html: `  •  Displaying only the relevant areas focused by Simon (e.g., when Poland is mentioned, only Poland is visible).`,
        },
        {
          type: "text",
          html: `  •  Differentiating elevations by using slightly darker shades for higher altitudes, adding depth and a brutalist concrete effect.`,
        },
        {
          type: "text",
          html: `  •  Incorporating lasers projected directly onto the maps to simulate realistic models. These lasers also added contrast to the terrain by reflecting light.`,
        },
        {
          type: "image",
          src: `/images/project/simon-pologne/5.gif`,
          alt: "3D map evolution",
        },
        {
          type: "text",
          html: `<br/><br/>03.2  •  DATA RELIABILITY & PRECISION`,
        },
        {
          type: "text",
          html: `<br/>Given the sensitive and serious nature of the subject, accuracy was paramount. We avoided assumptions and interpretations in both Simon's narration and the illustrative content`,
        },
        {
          type: "text",
          html: `  •  Points of interest were based on maps provided by Polish authorities.`,
        },
        {
          type: "text",
          html: `  •  Topographical information was sourced from the heightmapper of the Tangram project by Mapzen, an open-source initiative under the Linux Foundation.`,
        },
        {
          type: "text",
          html: `  •  Borders were delineated using a combination of satellite imagery and outlines from Google Maps.`,
        },
        {
          type: "image",
          src: `/images/project/simon-pologne/6.gif`,
          alt: "3D map evolution",
        },
        {
          type: "text",
          html: `<br/><br/>04  •  MOTION DESIGN & GRAPHICS`,
        },
        {
          type: "text",
          html: `<br/>To create the distinctive laser effects, I made 2D motion graphics in black and white. The 2D animations and 3D camera movements were carefully synchronized with Simon's commentary.`,
        },
        {
          type: "text",
          html: `  •  The iconography is a mix of IBM's Carbon design system icons and my own designs inspired by them`,
        },
        {
          type: "text",
          html: `  •  Each map features GPS coordinates in the top left corner and Simon's logo, designed by typographer Léo (Travisuals) Villars, in the bottom right corner.`,
        },
        {
          type: `vimeo`,
          html: `<iframe style="width: 100%" src="https://player.vimeo.com/video/929330269?muted=1&h=c1fbef0fcf&amp;title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autoplay=1&amp;loop=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="1440" height="1100" frameborder="0" allow="autoplay; picture-in-picture" allowfullscreen sandbox="allow-same-origin allow-scripts allow-pointer-lock allow-forms"></iframe>`,
        },
        {
          type: "text",
          html: ``,
        },
        {
          type: "text",
          html: `<br/><br/>05  •  CONCLUSION`,
        },
        {
          type: "text",
          html: `<br/>This project represents a confluence of geopolitical narrative and innovative 3D mapping, offering viewers a unique lens through which to understand complex border dynamics. Through meticulous design and data curation, we've crafted an immersive experience that complements Simon Puech's insightful exploration of the Polish border and its socio-political implications.`,
        },
        {
          type: `youtube`,
          html: `<iframe width="1280" height="720" src="https://www.youtube.com/embed/9vJ5x4b9RJs?rel=0&showinfo=0&modestbranding=1" title="YouTube video player" frameborder="0"; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />`,
        },
      ]
    },

    {
      url: "basti_3d_branding",
      title: ["Basti", "3D", "Branding"],
      color: "#FFFFFF",
      cover: "/images/project/basti/Cover.webp",
      filtres: ["3D", "Branding"],
      date: "October 2023",
      stats: { des: 100, mod: 0, dev: 0 },

      links: [
        {
          name: "More on Behance",
          url: "https://www.behance.net/gallery/185048803/BastiUI-3D-Branding",
        },
        {
          name: "Basti's Youtube channel",
          url: "https://www.youtube.com/c/BastiUI",
        },
        {
          name: "Sound design by Lunath",
          url: "https://open.spotify.com/artist/773SnwBYAvo9JlHuulUvMu",
        },
      ],
      summary: `Bastien approached me to rebrand his avatar, encompassing profile pictures, banners, and videos that reflect his unique universe.
      `,

      content: [
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/891208874?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: "text",
          html: `<br/><br/>Who is Basti ?`,
        },
        {
          type: "text",
          html: `Basti, also known as BastiUI, is a streamer, YouTuber, and interface designer (UX/UI) with a distinctive style.`,
        },
        {
          type: "image",
          src: `/images/project/basti/Image1.webp`,
          alt: "All faces",
        },
        {
          type: "text",
          html: `<br/><br/>01 ─ Inspiration`,
        },
        {
          type: "text",
          html: `Inspired by isometric character design, I drew influence from Blansable's work for Justin Buisson and 3D Microsoft emojis.`,
        },
        {
          type: "image",
          src: `/images/project/basti/Image2.webp`,
          alt: "References",
        },
        {
          type: "text",
          html: `<br/><br/>02 ─ Sketches`,
        },
        {
          type: "text",
          html: `The sketching phase involved several iterations to create a 3D character that resonates well.`,
        },
        {
          type: "image",
          src: `/images/project/basti/Image3.webp`,
          alt: "Sketches",
        },
        {
          type: "text",
          html: `I conceptualized various waiting animations incorporating references to Basti's universe, though not all were selected.`,
        },
        {
          type: "image",
          src: `/images/project/basti/Image4.webp`,
          alt: "Sketches",
        },
        {
          type: "text",
          html: `<br/><br/>03 ─ Modeling Evolution`,
        },
        {
          type: "text",
          html: `Navigating through the modeling evolution, I learned to create 3D characters, progressing from a minimalist v1 to a v2 that closely resembled the final result. Refinements focused on perfecting hair loops, rounding the face, and achieving a more lively front strand.`,
        },
        {
          type: "image",
          src: `/images/project/basti/Image5.webp`,
          alt: "Modeling evolution",
        },
        {
          type: "text",
          html: `<br/><br/>04 ─ Texturing`,
        },
        {
          type: "text",
          html: `The texturing process involved adapting a reference style with a unique "clay-like" shader and adding patterns for depth.`,
        },
        {
          type: "image",
          src: `/images/project/basti/Image6.webp`,
          alt: "Focus on textures",
        },
        {
          type: "text",
          html: `For Basti, I made a wave pattern for texture in the hair and created other reflective patterns representing different transformations.`,
        },
        {
          type: "image",
          src: `/images/project/basti/Image7.webp`,
          alt: "List of patterns",
        },
        {
          type: "text",
          html: `<br/><br/>05 ─ Rig`,
        },
        {
          type: "text",
          html: `Multiple skeletons allow the animation of eyes, mustache, and the front strand of hair.`,
        },
        {
          type: "image",
          src: `/images/project/basti/Gif1.gif`,
          alt: "Rig in motion",
        },
        {
          type: "text",
          html: `<br/><br/>06 ─ Props`,
        },
        {
          type: "text",
          html: `Every prop was meticulously modeled and textured for a bespoke touch.`,
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/basti/Gif2.gif",
              alt: `Cap`,
            },
            {
              type: "image",
              src: "/images/project/basti/Gif3.gif",
              alt: `Airpod max`,
            },
          ],
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/basti/Gif4.gif",
              alt: `DT770`,
            },
            {
              type: "image",
              src: "/images/project/basti/Gif5.gif",
              alt: `Cursor`,
            },
            {
              type: "image",
              src: "/images/project/basti/Gif6.gif",
              alt: `Train hat`,
            },
          ],
        },
        {
          type: "text",
          html: `<br/><br/>07 ─ Post Production`,
        },
        {
          type: "text",
          html: `In post-production, video exports were in EXR 32-bit, and color corrections were done using DaVinci Resolve.`,
        },
        {
          type: "image",
          src: `/images/project/basti/Image8.webp`,
          alt: "Befor/After colorgrading",
        },
        {
          type: "text",
          html: `<br/><br/>Render`,
        },
        {
          type: "text",
          html: `The final output includes 4 profile pictures and 3 banners tailored for various YouTube, Spotify, and Twitch channels.`,
        },
        {
          type: "image",
          src: `/images/project/basti/Image9.webp`,
          alt: "Banners",
        },
        {
          type: "image",
          src: `/images/project/basti/Image10.webp`,
          alt: "Banners and profile pictures",
        },
        {
          type: "text",
          html: `Additionally, there are 9 seamlessly looping waiting screens, showcasing the comprehensive transformation of Basti's 3D avatar.`,
        },
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/888860015?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/888855188?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/888855197?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/888855146?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/888855262?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/888855217?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/888855122?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/888855237?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/888855280?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
      ],
    },

    {
      url: "elixir_health_app_system",
      title: ["Elixir", "Health", "App System"],
      color: "#FFFFFF",
      cover: "/images/project/elixir-app/Cover.webp",
      filtres: ["UX/UI"],
      date: "October 2023",
      stats: { des: 25, mod: 25, dev: 50 },

      links: [
        {
          name: "More on Behance",
          url: "https://www.behance.net/gallery/171952407/Elixir-Health-App-System",
        },
        {
          name: "Elixir website",
          url: "https://elixir.health/",
        },
      ],
      summary: `Elixir is a revolutionary medical software that focuses on fertility. It is a web app that connects patients with their doctors. A Parisian hospital requested the creation of a mobile app for this purpose.`,

      content: [
        {
          type: "text",
          html: `Brief`,
        },
        {
          type: "text",
          html: `The brief for this project is to meet the doctors' needs in managing cases quickly from anywhere, including transmitting treatment progress. The user experience (UX) is of utmost importance, with an efficient user interface (UI) that allows for iterations with the users, as doctors have slightly different workflows.`,
        },
        {
          type: "text",
          html: `<br/><br/>Environment`,
        },
        {
          type: "text",
          html: `In the medical field, efficiency and speed are crucial. After discussing with several doctors, we realized the importance of visual and motor reflexes in their interaction with interfaces. The UI should minimize unnecessary movements and prioritize elements effectively.`,
        },
        {
          type: "image",
          src: `/images/project/elixir-app/Image1.webp`,
          alt: "Brief image",
        },
        {
          type: "text",
          html: `<br/><br/>Userflow & Priorities`,
        },
        {
          type: "text",
          html: `Based on our user research, we understood that quick access to information is essential. We need to consolidate multiple pieces of information on the same screen to enable doctors to synthesize the patient's condition rapidly.`,
        },
        {
          type: "text",
          html: `Additionally, if modifications to the treatment plan are necessary, notifications should be sent to the patients.`,
        },
        {
          type: "image",
          src: `/images/project/elixir-app/Image2.webp`,
          alt: "Choices whiteboard",
        },
        {
          type: "text",
          html: `The Patient page and forms are the key areas of focus, requiring efficiency and well-organized layouts.`,
        },
        {
          type: "text",
          html: `<br/><br/>References`,
        },
        {
          type: "text",
          html: `Our aim is to create a friendly interface that presents a large amount of data in a clear manner.`,
        },
        {
          type: "image",
          src: `/images/project/elixir-app/Image3.webp`,
          alt: "Material You",
        },
        {
          type: "text",
          html: `Material YOU : rounded & touches of colors look friendly<br/><br/>`,
        },
        {
          type: "image",
          src: `/images/project/elixir-app/Image4.webp`,
          alt: "Outlook APP",
        },
        {
          type: "text",
          html: `Outlook App : clean UI & efficiency<br/><br/>`,
        },
        {
          type: "image",
          src: `/images/project/elixir-app/Image5.webp`,
          alt: "Reminder IOS",
        },
        {
          type: "text",
          html: `Reminder IOS : Efficient form – Use already existing reflexes`,
        },
        {
          type: "text",
          html: `<br/><br/>Design System<br/><br/>01 — Colors`,
        },
        {
          type: "text",
          html: `In the medical environment, clarity and brightness are essential. Therefore, we have chosen a color palette that reflects a well-lit atmosphere, emphasizing a sense of cleanliness and professionalism.`,
        },
        {
          type: "text",
          html: `The light mode is favored, providing a visually comfortable experience for users. Furthermore, hospitals are a well-lit environment, which reinforces our choice. The color scheme aims to create a soothing and reassuring ambiance while ensuring optimal readability and usability.`,
        },
        {
          type: "image",
          src: `/images/project/elixir-app/Image6.webp`,
          alt: "Colors background",
        },
        {
          type: "image",
          src: `/images/project/elixir-app/Image7.webp`,
          alt: "Colors",
        },
        {
          type: "text",
          html: `<br/><br/>02 — Patient Page / Stimulation`,
        },
        {
          type: "text",
          html: `The Patient Page is the focal point of the app, where doctors will gather and interpret the most recent data about their patients. It needs to provide a comprehensive overview and stimulate efficient decision-making.`,
        },
        {
          type: "text",
          html: `Our design approach focuses on simplicity and clarity, with an intuitive layout that prioritizes relevant information. Clear typography, structured data presentation, and logical grouping of elements contribute to a seamless user experience. The page will facilitate quick assimilation of patient information, allowing doctors to make informed decisions promptly.`,
        },
        {
          type: "image",
          src: `/images/project/elixir-app/Image8.webp`,
          alt: "Main Screen",
        },
        {
          type: "image",
          src: `/images/project/elixir-app/Image9.webp`,
          alt: "Main datas",
        },
        {
          type: "image",
          src: `/images/project/elixir-app/Image10.webp`,
          alt: "Cell with data",
        },
        {
          type: "image",
          src: `/images/project/elixir-app/Image11.webp`,
          alt: "Header",
        },
        {
          type: "image",
          src: `/images/project/elixir-app/Image12.webp`,
          alt: "Treatments screen",
        },
        {
          type: "text",
          html: `<br/><br/>03 — Forms`,
        },
        {
          type: "text",
          html: `Forms play a vital role in capturing and updating patient data. To optimize the usability of these forms, we have implemented a dual approach.`,
        },
        {
          type: "text",
          html: `The compact style enables doctors to gather essential information quickly. In contrast, the extended style allows for data editing. This flexibility strikes a balance between efficiency and depth, ensuring that doctors can navigate through the forms efficiently without compromising on data accuracy and completeness.`,
        },
        {
          type: "image",
          src: `/images/project/elixir-app/Image13.webp`,
          alt: "Lists screen",
        },
        {
          type: "image",
          src: `/images/project/elixir-app/Image14.webp`,
          alt: "Forms cell",
        },
        {
          type: "image",
          src: `/images/project/elixir-app/Image15.webp`,
          alt: "Forms screens",
        },
        {
          type: "text",
          html: `<br/><br/>04 — Other pages`,
        },
        {
          type: "image",
          src: `/images/project/elixir-app/Image16.webp`,
          alt: "Medication list screen",
        },
        {
          type: "image",
          src: `/images/project/elixir-app/Image17.webp`,
          alt: "List access",
        },
        {
          type: "image",
          src: `/images/project/elixir-app/Image18.webp`,
          alt: "Client screen",
        },
        {
          type: "image",
          src: `/images/project/elixir-app/Image19.webp`,
          alt: "Log screen",
        },
      ],
    },

    {
      url: "exaecut",
      title: ["Exæcut"],
      color: "#F0EEF7",
      cover: "/images/project/exaecut/Cover.webp",
      filtres: ["3D", "Motion"],
      date: "April 2023",
      stats: { des: 100, mod: 0, dev: 0 },

      links: [
        {
          name: "More on Behance",
          url: "https://www.behance.net/gallery/170546709/Exaecut-Hero-Video",
        },
        {
          name: "Exæcut",
          url: "https://twitter.com/Exaecut",
        },
      ],
      summary: `Exæcut is a collective of video editors with the motto “Créez plus, créez mieux, profitez des meilleurs moments” (in English “Create more, create better, enjoy the best moments”).`,

      content: [
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/826523353?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Exaecut Hero Video"></iframe></div>`,
        },
        {
          type: "image",
          src: `/images/project/exaecut/Image1.webp`,
          alt: "Brief",
        },
        {
          type: "text",
          html: `<br/><br/>The Branding`,
        },
        {
          type: "text",
          html: `The collective already had a branding, a logo, and colors that I had to work with.`,
        },
        {
          type: "image",
          src: `/images/project/exaecut/Image2.webp`,
          alt: "Exaecut Branding",
        },
        {
          type: "text",
          html: `<br/><br/>Reflection & Technical choices`,
        },
        {
          type: "image",
          src: `/images/project/exaecut/Image3.webp`,
          alt: "Reflexion whiteboard",
        },
        {
          type: "text",
          html: `<br/><br/>Storyboard`,
        },
        {
          type: "image",
          src: `/images/project/exaecut/Image4.webp`,
          alt: "Storyboard",
        },
        {
          type: "text",
          html: `<br/><br/><br/><br/>01 — Timeline`,
        },
        {
          type: "text",
          html: `I was inspired by the timeline of Premiere Pro to create a custom shader. Then, I used Blender to create the camera movement and deformations. Each line will be rendered independently to be used as a mask in after effect.`,
        },
        {
          type: "text",
          html: `References`,
        },
        {
          type: "image",
          src: `/images/project/exaecut/Image5.webp`,
          alt: "Timeline premiere pro",
        },
        {
          type: "text",
          html: `Custom Textures`,
        },
        {
          type: "image",
          src: `/images/project/exaecut/Image6.webp`,
          alt: "My timeline texture",
        },
        {
          type: "image",
          src: `/images/project/exaecut/Gif1.gif`,
          alt: "3D deformation",
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/exaecut/Gif2.gif",
              alt: `Black and white Matte`,
            },
            {
              type: "image",
              src: "/images/project/exaecut/Gif3.gif",
              alt: `Final`,
            },
          ],
        },
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/826525851?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Exaecut Hero Video"></iframe></div>`,
        },
        {
          type: "text",
          html: `<br/><br/><br/><br/>02 — Tools`,
        },
        {
          type: "text",
          html: `Cinema stripes and elements related to audiovisual apparition`,
        },
        {
          type: "image",
          src: `/images/project/exaecut/Gif4.gif`,
          alt: "3D shapes",
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/exaecut/Gif6.gif",
              alt: `Matte bands`,
            },
            {
              type: "image",
              src: "/images/project/exaecut/Gif5.gif",
              alt: `Final bands`,
            },
          ],
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/exaecut/Gif7.gif",
              alt: `Matte curve`,
            },
            {
              type: "image",
              src: "/images/project/exaecut/Gif8.gif",
              alt: `Final curve`,
            },
          ],
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/exaecut/Gif10.gif",
              alt: `Matte tools`,
            },
            {
              type: "image",
              src: "/images/project/exaecut/Gif9.gif",
              alt: `Final tools`,
            },
          ],
        },
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/826535033?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Exaecut Hero Video"></iframe></div>`,
        },
        {
          type: "text",
          html: `<br/><br/><br/><br/>03 — Black Hole`,
        },
        {
          type: "text",
          html: `Cinema stripes and elements related to audiovisual apparition`,
        },
        {
          type: "image",
          src: `/images/project/exaecut/Gif13.gif`,
          alt: "Black hole 3",
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/exaecut/Gif11.gif",
              alt: `Black hole ring texture animated`,
            },
            {
              type: "image",
              src: "/images/project/exaecut/Gif12.gif",
              alt: `Black hole texture animated`,
            },
          ],
        },
        {
          type: `vimeo`,
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/826535683?muted=1&autoplay=1&loop=1&autopause=0;h=912a19c792&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Exaecut Hero Video"></iframe></div>`,
        },
        {
          type: "text",
          html: `<br/><br/><br/><br/>Unused Exploration`,
        },
        {
          type: "image",
          src: `/images/project/exaecut/Image7.webp`,
          alt: "unused sketch",
        },
        {
          type: "text",
          html: `<br/><br/><br/><br/>Frames`,
        },
        {
          type: "image",
          src: `/images/project/exaecut/Image8.webp`,
          alt: "Frames from timeline part",
        },
        {
          type: "image",
          src: `/images/project/exaecut/Image9.webp`,
          alt: "Frames from tools part",
        },
        {
          type: "image",
          src: `/images/project/exaecut/Image10.webp`,
          alt: "Frames from blackhole part 1",
        },
        {
          type: "image",
          src: `/images/project/exaecut/Image11.webp`,
          alt: "Frames from blackhole part 2",
        },
        {
          type: "image",
          src: `/images/project/exaecut/Image12.webp`,
          alt: "Frames from the explosion part",
        },
      ],
    },

    {
      url: "art_and_mecha",
      title: ["Art & Mecha"],
      color: "#E2E2E2",
      cover: "/images/project/art-mecha/Cover.webp",
      filtres: ["3D"],
      date: "March 2023",
      stats: { des: 75, mod: 10, dev: 10 },

      summary: `After my work on the Hackathon H&C, I wanted to further its concept in an independent personal project. The increased complexity of the model reflect my progress in 3D.`,

      content: [
        {
          type: `text`,
          html: `I wanted to improve my skills in 3D modeling, animation automation and octane shadering. So I started with the concept of the <a href="https://www.kubeandpill.xyz/projects/8" target="_blank">HHC project</a> and chose another statue as a base.`,
        },
        {
          type: `split`,
          content: [
            {
              type: `image`,
              src: `/images/project/art-mecha/Image1.webp`,
              alt: `Original statue`,
            },
            {
              type: `image`,
              src: `/images/project/art-mecha/Image2.webp`,
              alt: `3D sketch`,
            },
          ],
        },
        {
          type: `split`,
          content: [
            {
              type: `vimeo`,
              html: `<div style="padding:125% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/828786093?muted=1&autoplay=1&loop=1&autopause=0;h=94a43d005e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Gif1"></iframe></div>`,
            },
            {
              type: `vimeo`,
              html: `<div style="padding:125% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/828788285?muted=1&autoplay=1&loop=1&autopause=0;h=6ace72911c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Gif2"></iframe></div>`,
            },
          ],
        },
        {
          type: `image`,
          src: `/images/project/art-mecha/Image3.webp`,
          alt: `Render Full`,
        },
        {
          type: `image`,
          src: `/images/project/art-mecha/Image4.webp`,
          alt: `Render Full with shader`,
        },
        {
          type: `image`,
          src: `/images/project/art-mecha/Image5.webp`,
          alt: `Render Full with shader close up`,
        },
        {
          type: `image`,
          src: `/images/project/art-mecha/Image6.webp`,
          alt: `Render Full with shader close up 2`,
        },
        {
          type: `image`,
          src: `/images/project/art-mecha/Image7.webp`,
          alt: `Render Full with shader  close up 3`,
        },
        {
          type: `image`,
          src: `/images/project/art-mecha/Image8.webp`,
          alt: `Render Full with shader close up 4`,
        },
        {
          type: `image`,
          src: `/images/project/art-mecha/Image9.webp`,
          alt: `Render Full with shader close up 5`,
        },
        {
          type: `image`,
          src: `/images/project/art-mecha/Image10.webp`,
          alt: `Render Full with shader close up 6`,
        },
        {
          type: `image`,
          src: `/images/project/art-mecha/Image11.webp`,
          alt: `Render Full with shader close up 7`,
        },
        {
          type: `image`,
          src: `/images/project/art-mecha/Image12.webp`,
          alt: `Render Full with lastg compositing and light wide`,
        },
        {
          type: `image`,
          src: `/images/project/art-mecha/Image13.webp`,
          alt: `Render Full with lastg compositing and light`,
        },
      ],
    },

    {
      url: "samedi_sport",
      title: ["Samedi Sport"],
      color: "#ffffff",
      cover: "/images/project/samedi-sport/Cover.webp",
      filtres: ["Branding", "Motion", "3D", "Dev.", "UX/UI"],
      date: "October 2023",
      stats: { des: 75, mod: 10, dev: 30 },

      summary: `I made the new visual identity, motion branding system, streaming assets and toolkit for the Samedi Sport show, a french broadcast hosted by Rivenzi. The challenge was to create a coherent design system that could highlighting the vitality of the sport and the chill of a chat with friends while remaining distinct among other traditional sports programs.`,

      links: [
        {
          name: "More on Behance",
          url: "https://www.behance.net/gallery/156461063/Samedi-Sport",
        },
        {
          name: "Rivenzi",
          url: "https://www.twitch.tv/rivenzi",
        },
      ],

      content: [
        {
          type: "vimeo",
          html: '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/760471330?muted=1&autoplay=1&loop=1&autopause=0;h=3ca126fa98&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Samedi Sport - Intro"></iframe></div>',
        },
        {
          type: "text",
          html: `What is Samedi Sport ?`,
        },
        {
          type: "text",
          html: `Samedi Sport is a Twitch show made by Rivenzi. The show covers the achievements, performances and major sporting competitions. Speakers and famous personalities from the French sports world are invited to discuss various subjects. The broadcast was in remote and will be on set for the first time this year. The atmosphere is relaxed and the group talks without filter which brings funny moments.`,
        },
        {
          type: "text",
          html: `<br/><br/>Who is Rivenzi ?`,
        },
        {
          type: "text",
          html: `Rivenzi (Theo) is a great figure of the French streaming. In a few years he became a major figure of the twitch platform. He started as a video game competition host, he has slowly put forward his passion for history and sports to create several shows.`,
        },
        {
          type: "image",
          src: "/images/project/samedi-sport/Image1.webp",
          alt: `Live screenshot`,
        },
        {
          type: "text",
          html: `<br/><br/>The Brief`,
        },
        {
          type: "text",
          html: `Design the Samedi Sport package, creating an entire visual identity with a flexible branding, and tools to create unique content while following the new design system.`,
        },
        {
          type: "text",
          html: `<br/><br/><br/><br/>01 — Identity Elements`,
        },
        {
          type: "text",
          html: `<br/><br/>Logo and Typography`,
        },
        {
          type: "text",
          html: `I decided to focus the brand on speed and power. The typography highlights an epic and dynamic side. They are used only in capslock to make them more impressive.`,
        },
        {
          type: "image",
          src: "/images/project/samedi-sport/Image2.webp",
          alt: `Logo`,
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/samedi-sport/Image3.webp",
              alt: `Font 1`,
            },
            {
              type: "image",
              src: "/images/project/samedi-sport/Image4.webp",
              alt: `Font 2`,
            },
          ],
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/samedi-sport/Image5.webp",
              alt: `Font Illustration Concept 1`,
            },
            {
              type: "image",
              src: "/images/project/samedi-sport/Image6.webp",
              alt: `Font Illustration Concept 2`,
            },
            {
              type: "image",
              src: "/images/project/samedi-sport/Image7.webp",
              alt: `Font Illustration Concept 3`,
            },
          ],
        },
        {
          type: "text",
          html: `<br/><br/>Counter Form`,
        },
        {
          type: "text",
          html: `The central part of this identity is the counter form. It is intended to put forward the movement and the power. It is based on the map of France, simplified into a non-perfect hexagon, which can be find in the center.`,
        },
        {
          type: "image",
          src: "/images/project/samedi-sport/Image8.webp",
          alt: `Shape evolution`,
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/samedi-sport/Gif1.gif",
              alt: `Counter Form Animation Speed`,
            },
            {
              type: "image",
              src: "/images/project/samedi-sport/Gif2.gif",
              alt: `Counter Form Animation Impact`,
            },
            {
              type: "image",
              src: "/images/project/samedi-sport/Gif3.gif",
              alt: `Counter Form Animation Power`,
            },
          ],
        },
        {
          type: "text",
          html: `This form contains 3 different parts. Each piece is made of 3 fixed points and 3 moving points. They are intended to change according to the movements of the sports. A custom tool on Figma and Illustrator makes the creation of variations easier`,
        },
        {
          type: "image",
          src: "/images/project/samedi-sport/Gif4.gif",
          alt: `Shape morphing`,
        },
        {
          type: "text",
          html: `<br/><br/>Colors`,
        },
        {
          type: "text",
          html: `The colors were chosen to be different from other French shows, they remain bright and saturated to be energetic. They may vary depending on certain events. For example, in the context of the rolex paris master we used the colors of the rolex tennis events.`,
        },
        {
          type: "image",
          src: "/images/project/samedi-sport/Image9.webp",
          alt: `Main Color theme`,
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/samedi-sport/Image10.webp",
              alt: `Color theme tennis`,
            },
            {
              type: "image",
              src: "/images/project/samedi-sport/Image11.webp",
              alt: `Color theme france`,
            },
          ],
        },
        {
          type: "text",
          html: `<br/><br/><br/><br/>02 — Stream Assets`,
        },
        {
          type: "text",
          html: `<br/><br/>Layout and UI`,
        },
        {
          type: "text",
          html: `The layouts have been designed to leave a maximum of space for cameras and content. Masks are applied to break the rectangular shape and to highlight the graphic identity.`,
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/samedi-sport/Image12.webp",
              alt: `Layout 1`,
            },
            {
              type: "image",
              src: "/images/project/samedi-sport/Image13.webp",
              alt: `Layout 1 screenshot`,
            },
          ],
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/samedi-sport/Image14.webp",
              alt: `Layout 2`,
            },
            {
              type: "image",
              src: "/images/project/samedi-sport/Image15.webp",
              alt: `Layout 2 screenshot`,
            },
          ],
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/samedi-sport/Image16.webp",
              alt: `Layout 3`,
            },
            {
              type: "image",
              src: "/images/project/samedi-sport/Image17.webp",
              alt: `Layout 3 screenshot`,
            },
          ],
        },
        {
          type: "text",
          html: `<br/><br/>3D Environment`,
        },
        {
          type: "text",
          html: `The gozulting studio used for the show, is equipped with a curved screen. It was decided to make a 3D environment to achieve a realistic integration. We wanted a chill, pub-like atmosphere. Modeling and rendering was done in Blender Octane. Everything was done manually. The studio lights have been simulated in the 3D environment to increase the realism.​​​​​​`,
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/samedi-sport/Image18.webp",
              alt: `3D environment 1`,
            },
            {
              type: "image",
              src: "/images/project/samedi-sport/Image19.webp",
              alt: `3D environment 2`,
            },
          ],
        },
        {
          type: "image",
          src: "/images/project/samedi-sport/Image20.webp",
          alt: `Final 3D Environment`,
        },
        {
          type: "text",
          html: `<br/><br/><br/><br/>03 — Motion`,
        },
        {
          type: "text",
          html: `<br/><br/>Videos Compositing`,
        },
        {
          type: "text",
          html: `Jingles are composed of archive videos clips modified to fit with the branding : First, I unify them, I apply black and white + blur + sharpen +  bightness and contrast + screen fusion mode. Second, I put sharpen texture loop with a frame rate based on the music rythme.`,
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/samedi-sport/Gif5.gif",
              alt: `Befor Compositing`,
            },
            {
              type: "image",
              src: "/images/project/samedi-sport/Gif6.gif",
              alt: `Final Compositing`,
            },
          ],
        },
        {
          type: "text",
          html: `<br/><br/>Fonts`,
        },
        {
          type: "text",
          html: `I made an animation for each font with the After Effect animator properties in order to be able to produce an infinite number of titles as simply as possible. Shapes are also used in the background to increase the contrast between the images and the text.​`,
        },
        {
          type: "split",
          content: [
            {
              type: "image",
              src: "/images/project/samedi-sport/Gif8.gif",
              alt: `Font motion exemple 1`,
            },
            {
              type: "image",
              src: "/images/project/samedi-sport/Gif9.gif",
              alt: `Font motion exemple 2`,
            },
          ],
        },
        {
          type: "text",
          html: `<br/><br/>Result`,
        },
        {
          type: "vimeo",
          html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/770242116?muted=1&autoplay=1&loop=1&autopause=0;h=f21664c5e5&badge=0&autopause=0&player_id=0&app_id=58479/embed" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen frameborder="0" style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`,
        },
        {
          type: "text",
          html: `<br/><br/><br/><br/>04 — Motion Design Template`,
        },
        {
          type: "text",
          html: `To speed up the assets creations, I made Essentials Graphics on After Effect also called MOGRT. Animations are automatically adapted to the content with code.`,
        },
        {
          type: "text",
          html: `<br/><br/>Name Overlays`,
        },
        {
          type: "text",
          html: `Just change texts and all shapes and animations update themself.`,
        },
        {
          type: "image",
          src: "/images/project/samedi-sport/Gif10.gif",
          alt: `Name MOGRT`,
        },
        {
          type: "text",
          html: `<br/><br/>Credits Outro`,
        },
        {
          type: "image",
          src: "/images/project/samedi-sport/Gif11.gif",
          alt: `Creadit MOGRT`,
        },
        {
          type: "text",
          html: `<br/><br/><br/><br/>05 — Costum Software and Program Bar`,
        },
        {
          type: "text",
          html: `<br/><br/>Context and Objectifs`,
        },
        {
          type: "text",
          html: `The last part of the mission was to find a technical way to display the program. It was necessary to allow the spectators to know which subject were and will be covered. So, I created a custom software displayed with a browser source (on OBS or VMIX) using React.js and Node.`,
        },
        {
          type: "text",
          html: `<br/><br/>UX Purposes`,
        },
        {
          type: "text",
          html: `This solution required some UX thinking to make it the most suitable for the production team and viewers. I needed a solution that could be used with a streamdeck for the producer and connected to the Twitch Chat for viewers, who can make it appear with triggerwords. So, the bar can be displayed if a viewer simply asks "what is the prog ?".`,
        },
        {
          type: "image",
          src: "/images/project/samedi-sport/Gif12.gif",
          alt: `Program Bar`,
        },
      ],
    },

    {
      url: "devfest_strasbourg_2022",
      title: ["Devfest", "Strasbourg"],
      cover: "/images/project/devfest/Cover.webp",
      filtres: ["Motion", "Branding"],
      date: "April 2022",
      stats: {
        des: 65, mod: 10, dev: 10
      },

      summary: `The Devfest Strasbourg 2022 is the third edition of the largest IT conference in the East of France. It is aimed at students, professionals or simply people who are curious about technology. Each year the event is linked to a special theme. For 2022 the chosen theme was funfair.`,
      links: [
        {
          name: "More on Behance",
          url: "https://www.behance.net/gallery/142842797/DevFest-Strasbourg-2022",
        },
        {
          name: "Twitter",
          url: "https://twitter.com/DevfestSXB",
        },
      ],

      content: [
        {
          type: `split`,
          content: [
            {
              type: `image`,
              src: `/images/project/devfest/Image1.webp`,
              alt: `Logo Dev Fest Color 1`,
            },
            {
              type: `image`,
              src: `/images/project/devfest/Image2.webp`,
              alt: `Logo Dev Fest Color 2`,
            },
          ],
        },
        {
          type: `video`,
          src: `/images/project/devfest/Gif1.webm`,
        },
        {
          type: `image`,
          src: `/images/project/devfest/Image3.webp`,
          alt: `Font et logo`,
        },
        {
          type: `image`,
          src: `/images/project/devfest/Image4.webp`,
          alt: `Iconographies`,
        },
        {
          type: `image`,
          src: `/images/project/devfest/Image5.webp`,
          alt: `Color Paterns`,
        },
        {
          type: `image`,
          src: `/images/project/devfest/Image6.webp`,
          alt: `Grid and stickers`,
        },
        {
          type: `image`,
          src: `/images/project/devfest/Image7.webp`,
          alt: `Social media contents`,
        },
        {
          type: `image`,
          src: `/images/project/devfest/Image8.webp`,
          alt: `Banner Devfest`,
        },
        {
          type: `text`,
          html: `This project was made with <a href="https://www.a2moy.fr" target="_blank">Auxence Demoy</a> for the iconography and identity.`,
        },
      ],
    },

    {
      url: "underscore",
      title: ["Underscore"],
      cover: "/images/project/underscore/Cover.webp",
      filtres: ["Branding", "Motion", "UX/UI"],
      date: "February 2022",
      stats: { des: 60, mod: 10, dev: 60 },

      summary: `Underscore_ is a talkshow that highlights the latest information about IT, development and hacking. A big part of the editorial line consists in discussing specific topics with experts: cyberwarfare, quantum computers, the use of fractals in computing, etc.`,
      links: [
        {
          name: "More on Behance",
          url: "https://www.behance.net/gallery/132118407/Underscore_-Talkshow",
        },
        {
          name: "Youtube channel",
          url: "https://www.youtube.com/c/UnderscoreTalk/featured",
        },
        {
          name: "Twitch channel",
          url: "https://www.twitch.tv/micode",
        },
        {
          name: "Instagram",
          url: "https://www.instagram.com/underscoretalk/",
        },
      ],

      content: [
        {
          type: `text`,
          html: `To keep a coherence with the IT world the identity has to be modern and serious. To push the Underscore_’s brand we put forward the use of two-tone color scheme that remind tech world.`,
        },
        {
          type: `video`,
          src: `/images/project/underscore/Gif1.webm`,
        },
        {
          type: `split`,
          content: [
            {
              type: `text`,
              html: `To make the producer's work easier we developed an AI that control cameras. We named it "Gabin". He have his own personnality. He has many ways to express, he can change his eyes shape and hands as required.<br/><br/>With time Gabin has evolve and he is now able to display tweets, polls or infos to the host. Moreover, the stream layout adjusts itself according to the space Gabin's messages can take.`,
            },
            {
              type: `video`,
              src: `/images/project/underscore/Gif2.webm`,
            },
          ],
        },
        {
          type: `split`,
          content: [
            {
              type: `image`,
              src: `/images/project/underscore/Image1.webp`,
              alt: `Render close up wing`,
            },
            {
              type: `text`,
              html: `The goal of those jingles is to make transitions between the different parts of the show. Our main inspirations was the visuals made by the Microsoft Design team. We used tools like Blender Octane to make the videos. Many parts of the jingles have alternatate versions, which allows the video editor to create different motions easily.`,
            },
          ],
        },
        {
          type: `video`,
          src: `/images/project/underscore/Gif3.webm`,
        },
        {
          type: `text`,
          html: `You can see them used in the videos below :`,
        },
        {
          type: `youtube`,
          html: `<iframe width="1280" height="720" src="https://www.youtube.com/embed/cYPLnnPLZpQ?rel=0&showinfo=0&modestbranding=1" title="YouTube video player" frameborder="0"; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />`,
        },
        {
          type: `youtube`,
          html: `<iframe width="1280" height="720" src="https://www.youtube.com/embed/YfXBOzPWU7c?rel=0&showinfo=0&modestbranding=1" title="YouTube video player" frameborder="0"; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />`,
        },
        {
          type: `text`,
          html: `Each shows has there own announcement on social networks. There are many constraints, the different size, the readability and the aesthetics. Figma was the best tool to create content on the go and effortless, in few clicks we are able to generate new post.`,
        },
        {
          type: `image`,
          src: `/images/project/underscore/Image2.webp`,
          alt: `Render close up wing`,
        },
      ],
    },

    {
      url: "micode_design_system",
      title: ["Micode", "Design System"],
      cover: "/images/project/micode/Cover.webp",
      filtres: ["Branding", "Motion", "UX/UI"],
      date: "February 2022",
      stats: { des: 60, mod: 10, dev: 40 },

      summary: `Micode is an IT Youtube Channel. Michaël, its creator wants to give access to knowledge in cybersecurity and computer science to a large part of the French IT community. In order to achieve this goal an entertainment news media was created, about IT News on Instagram. Short videos and interviews are published on Youtube. My job as a designer was to create a tech and friendly design system.`,
      links: [
        {
          name: "More on Behance",
          url: "https://www.behance.net/gallery/114067987/Hackathon-Histoire-Culture-2021",
        },
        {
          name: "Youtube channel",
          url: "https://www.youtube.com/channel/UCYnvxJ-PKiGXo_tYXpWAC-w/featured",
        },
        {
          name: "Instagram",
          url: "https://www.instagram.com/micode/",
        },
      ],

      content: [
        {
          type: `text`,
          html: `Micode is an IT Youtube Channel. Michaël, its creator wants to give access to knowledge in cybersecurity and computer science to a large part of the French IT community. In order to achieve this goal an entertainment news media was created, about IT News on Instagram. Short videos and interviews are published on Youtube. Our job as a designer was to create a tech and friendly design system.`,
        },
        {
          type: `video`,
          src: `/images/project/micode/Gif1.webm`,
        },
        {
          type: `text`,
          html: `For the creation of this art direction, we were 3 designers (<a href="https://twitter.com/BastiUi" target="_blank">Bastien</a>, <a href="https://twitter.com/SonyRouhaud" target="_blank">Sony</a> and me). For the concept phase, we worked blindly. No one knew the vision and interpretation of the others. This allowed us to explore many different styles..`,
        },
        {
          type: `image`,
          src: `/images/project/micode/Image1.webp`,
          alt: `Sketchs`,
        },
        {
          type: `text`,
          html: `After 3 weeks. we have merged our different ideas and we come up to a balance between tech, friendliness and modularity. After several iterations we decided to take an “M” merged with the famous “./” used to execute scripts in a terminal.`,
        },
        {
          type: `video`,
          src: `/images/project/micode/Gif2.webm`,
        },
        {
          type: `text`,
          html: `5 themes were created to vary contents and avoid repetition. They are based on a gradient that we have divided into 3 parts.`,
        },
        {
          type: `image`,
          src: `/images/project/micode/Image2.webp`,
          alt: `Themes`,
        },
        {
          type: `text`,
          html: `An entire design system was designed to generate Instagram posts. A series of templates and tools were built on Figma to allow a Community Manager to quickly create unique content.`,
        },
        {
          type: `image`,
          src: `/images/project/micode/Image3.webp`,
          alt: `Design system`,
        },
        {
          type: `image`,
          src: `/images/project/micode/Image4.webp`,
          alt: `Design system detailed`,
        },
        {
          type: `text`,
          html: `Micode is also a Youtube channel with interviews and short content. We had to find a solution to save time for the video editor. “MOGRT” were created to easily edit videos for various media.`,
        },
        {
          type: `video`,
          src: `/images/project/micode/Gif3.webm`,
        },
        {
          type: `youtube`,
          html: `<iframe width="1280" height="720" src="https://www.youtube.com/embed/ZZMjvJPxshI?rel=0&showinfo=0&modestbranding=1" title="YouTube video player" frameborder="0"; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />`,
        },
      ],
    },

    {
      url: "l_heure_du_dasagn",
      title: ["L'heure du", "dasagn"],
      cover: "/images/project/dasagn/Cover.webp",
      filtres: ["3D", "Motion"],
      date: "July 2021",
      stats: { des: 75, mod: 10, dev: 20 },

      summary: `BastiUI is a designer/content creator who creates videos on interface design. I created a jingle for his show: “L’heure du Dasagn” (It's dasagn time), using a music parody he had made. I modeled his office and animated it to create a dynamic motion design.`,

      content: [
        {
          type: `text`,
          html: `BastiUI is a content creator who creates <a href="https://www.youtube.com/c/BastiUI" target="_blank">videos</a> on interface design and <a href="https://www.twitch.tv/BastiUi" target="_blank">live</a> on Twitch. He contacted me to do a jingle for a show where he does live design.`,
        },
        {
          type: `split`,
          content: [
            {
              type: `youtube`,
              html: `<iframe width="1280" height="720" src="https://www.youtube.com/embed/J_BHqE3btoc?rel=0&showinfo=0&modestbranding=1" title="YouTube video player" frameborder="0"; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />`,
            },
            {
              type: `text`,
              html: `I started by doing all the modeling by myself. I was inspired by the pictures of the office that he sent me. Here a timelapse in several parts of the creation of assets.`,
            },
          ],
        },
        {
          type: `text`,
          html: `I made color and pattern animation on after effect and implement them on Blender with Camera mapping, image (video texture).`,
        },
        {
          type: `split`,
          content: [
            {
              type: `video`,
              src: `/images/project/dasagn/Gif1.webm`,
            },
            {
              type: `video`,
              src: `/images/project/dasagn/Gif2.webm`,
            },
            {
              type: `video`,
              src: `/images/project/dasagn/Gif3.webm`,
            },
          ],
        },
        {
          type: `text`,
          html: `You can see the final result on this video where he recaps the history around this jingle.`,
        },
        {
          type: `youtube`,
          html: `<iframe width="1280" height="720" src="https://www.youtube.com/embed/dyu9P1p1m5w?start=1290?rel=0&showinfo=0&modestbranding=1" title="YouTube video player" frameborder="0"; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />`,
        },
        {
          type: `text`,
          html: `Wallpapers have also been created and are available <a href="https://basti.gumroad.com/l/DASAGNWallpapers" target="_blank">here</a>.`,
        },
        {
          type: `split`,
          content: [
            {
              type: `image`,
              src: `/images/project/dasagn/Image1.webp`,
              alt: `UI App Sketch`,
            },
            {
              type: `image`,
              src: `/images/project/dasagn/Image2.webp`,
              alt: `UI App Sketch`,
            },
            {
              type: `image`,
              src: `/images/project/dasagn/Image3.webp`,
              alt: `UI App Sketch`,
            },
            {
              type: `image`,
              src: `/images/project/dasagn/Image4.webp`,
              alt: `UI App Sketch`,
            },
          ],
        },
      ],
    },

    {
      url: "diced_mango",
      title: ["Diced Mango"],
      cover: "/images/project/diced-mango/Cover.webp",
      filtres: ["3D", "Motion"],
      date: "September 2021",
      stats: { des: 75, mod: 20, dev: 20 },

      summary: `Lunath and Inscience, are two independent musicians. They contacted me to make the cover of their latest collaboration. Their single is called Diced Mango. The creation is photo realistic mangoes pieces. I made an image for the cover and a video loop for Spotify and Youtube.`,
      links: [
        {
          name: "Spotify",
          url: "https://open.spotify.com/track/5KcXb23uvlryLgg7xVCTzB?si=a138d6e907724f96&nd=1",
        },
      ],

      content: [
        {
          type: `split`,
          content: [
            {
              type: `text`,
              html: `I used blender to do all the modeling and Octane for the shader. I did not choose cycle to ease photorealism. The animation remains basic and the lighting highlights the subsurface scattering and the texture displacement.`,
            },
            {
              type: `image`,
              src: `/images/project/diced-mango/Image1.webp`,
              alt: `Single cover`,
            },
          ],
        },
        {
          type: `video`,
          src: `/images/project/diced-mango/Gif1.webm`,
        },
        {
          type: `youtube`,
          html: `<iframe width="1280" height="720" src="https://www.youtube.com/embed/IUxtdvBgp7s?rel=0&showinfo=0&modestbranding=1" title="YouTube video player" frameborder="0"; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />`,
        },
      ],
    },

    {
      url: "hackathon_histoire_and_culture",
      title: ["Hackathon", "Histoire &", "Culture"],
      cover: "/images/project/hhc/Cover.webp",
      filtres: ["3D", "Motion.", "Branding"],
      date: "March 2021",
      stats: { des: 75, mod: 10, dev: 10 },

      summary: `The History & Culture Hackathon is an event organized by Epitech Strasbourg students and open to the IONIS campus. It aims to make students work on innovative projects focused on history and culture over a weekend.`,
      links: [
        {
          name: "More on Behance",
          url: "https://www.behance.net/gallery/114067987/Hackathon-Histoire-Culture-2021",
        },
        {
          name: "Octane Render",
          url: "https://www.instagram.com/p/CMLRsYaCnSj/",
        },
      ],

      content: [
        {
          type: `text`,
          html: `The HHC is a private event created by IONIS students for IONIS students. The goal of this hackathon is to find and develop an idea which merge culture, history and tech. `,
        },
        {
          type: `text`,
          html: `The mechanic part of the statue was modeled on blender. Moreover all colored textures are generated with texture nodes.`,
        },
        {
          type: `video`,
          src: `/images/project/hhc/Gif1.webm`,
        },
        {
          type: `video`,
          src: `/images/project/hhc/Gif2.webm`,
        },
        {
          type: `text`,
          html: `I used my own assets to make the event poster and the promotional video.`,
        },
        {
          type: `image`,
          src: `/images/project/hhc/Image1.webp`,
          alt: `Poster HHC`,
        },
        {
          type: `youtube`,
          html: `<iframe width="1280" height="720" src="https://www.youtube.com/embed/kWHhbBtJ58A?rel=0&showinfo=0&modestbranding=1" title="YouTube video player" frameborder="0"; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />`,
        },
        {
          type: `image`,
          src: `/images/project/hhc/Image2.webp`,
          alt: `Banner HHC`,
        },
      ],
    },
  ],
};

export default config;
