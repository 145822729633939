import styled from 'styled-components';
import { keyframes } from 'styled-components'

function getRandomArbitrary(min, max, intOnly) {
    if (intOnly) {
        return  Math.round(Math.random() * (max - min) + min);
    }
    return Math.random() * (max - min) + min;
}

const randomSide = () => {
    let random = getRandomArbitrary(0, 1, true) === 0 ? -100 : 100
    return (random + "%")
}

const InAnim = (x) => keyframes`
    0% {
        transform: translateX(${x});
    }
    100% {
        transform: translateX(0%);
    }
`

const Letter = styled.div.attrs(props => ({
    style: {
        animationDuration: props.speed+"s",
        animationDelay: props.delay,
    },
  }))
`
    display: inline-block;
    white-space: pre;
    animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    animation-fill-mode: forwards;
    animation-name: ${() => InAnim(randomSide())};
    vertical-align: baseline;
    transform: translateX(-100%);
`

const LetterWrapper = styled.div`
    overflow: hidden;
    display: inline-block;
`

const LetterContainer = styled.div`
    margin-bottom: -1vw;
`

const AnimTranslateText = (props) => {
    let textArray = props.text.split("");
    let speed = props.speed ? props.speed : 1

    return (
        <LetterContainer>
            {textArray.map((letter, index) =>
                <LetterWrapper key={index + getRandomArbitrary(0, 100)} active>
                    <Letter speed={speed} delay={getRandomArbitrary(0, 10) * 50 * props.speed + "ms"}>
                        {letter}
                    </Letter>
                </LetterWrapper>
            )}
        </LetterContainer>
    )
}

export default AnimTranslateText;