import styled from "styled-components";
import React, { Component, useState, useEffect } from "react";
import { Blobs, AnimRandomText, AnimTranslateText } from '../components';
import {
    Paragraph,
    Button
} from '../components/Styled';

const Container = styled.div`
    background-color: #1F1F1F;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const Intro = styled.div`
    margin-top: 25vh;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.5vw;

    @media (max-width: 970px) {
        margin-top: 10vh;
    }
`

const Titre1 = styled.div`
    font-size: 5vw;

    @media (max-width: 970px) {
        font-size: 9vw;
    }
`

const Titre2 = styled.div`
    font-family: "Grafier-Dipslay";
    font-variation-settings: "wght" 655, "cnst" 100;
    line-height: 100%;
    font-size: 8.5vw;
    font-weight: normal;

    @media (max-width: 970px) {
        font-size: 17vw;
        margin-bottom: 30px;
    }
`

const ParagraphCustom = styled(Paragraph)`
    text-align: justify;
    width: 40vw;
    max-width: 600px;

    @media (max-width: 970px) {
        width: auto;
    }
`

const A = styled.a`
    text-decoration: underline;
    font-style: normal;
    font-weight: 500;
`

const Nav = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    padding: 30px 0;
    margin-top: calc(37vh - 13.5vw);
    background-image: url(/images/GridLight.svg);
    background-repeat: repeat;
    width: 100%;

    @media (max-width: 970px) {
        margin-top: 100px;
        padding: 60px 0;
    }
`

const ButtonContainer = styled.div`
    display: flex;
    gap: 30px;

    @media (max-width: 970px) {
        flex-direction: column;
        align-items: center;
    }
`

const DarkButton = styled(Button)`
    color: #F4F3F1;
    border-color: #F4F3F1;

    &:hover {
        background-color: #F4F3F1;
        color: #1F1F1F;
        border-width: rgba(244, 243, 241, 0);
    }

    @media (max-width: 970px) {
        width: auto;
    }
`

const LineContainer = styled.div`
    gap: 120px;
    margin-top: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    @media (max-width: 970px) {
        gap: 60px;
        margin-top: 90px;
    }
`

const Line = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10vw;

    @media (max-width: 970px) {
        gap: 60px;
        flex-direction: column;
    }
`

const LineWithplan = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 100px;
`

const BlobContainer = styled.div`
    width: 20vw;
    height: 20vw;
    max-width: 400px;
    max-height: 400px;
    background-color: #F4F3F1;
    border-radius: 20vw;
    overflow: hidden;
    flex-grow: 1;
    isolation: isolate;

    @media (max-width: 970px) {
        width: 250px;
        height: 250px;
        border-radius: 250vw;
    }
`

const ContainerDemo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
`

const Plan = styled.img`
    width: 70%;

    @media (max-width: 970px) {
        width: 90%;
    }
`

const Separator = styled.div`
    width: 100%;
    height: 160px;
    background-image: url(/images/GridLight.svg);
    background-repeat: repeat;

    @media (max-width: 970px) {
        height: 90px;
    }
`

const RandomContainer = styled.div`
    font-family: "Supply Mono";
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    font-size: 5vw;
    letter-spacing: -1%;
    margin-bottom: -1vw;

    @media (max-width: 970px) {
        font-size: 13vw;
    }
`

const TranslateContainer = styled.div`
    font-family: "Grafier-Dipslay";
    font-variation-settings: "wght" 655, "cnst" 100;
    line-height: 100%;
    font-size: 5vw;
    font-weight: normal;

    @media (max-width: 970px) {
        font-size: 11vw;
    }
`

class HowPage extends Component {
    constructor(props) {
        super(props);
        this.gradientRef = React.createRef();
        this.randomRef = React.createRef();
        this.translateRef = React.createRef();
    }

    componentDidMount() {
        this.props.onMount()
    }

    componentWillUnmount() {
        this.props.onUnmount()
    }

    render() {
        return (
            <Container>
                <Intro>
                    <Titre1><AnimRandomText speed={50} text="How I made this" /></Titre1>
                    <Titre2><AnimTranslateText text="WEBSITE" /></Titre2>
                </Intro>
                <ParagraphCustom style={{marginTop: "5vw"}}>
                    <p>This website is built in <ALink url="https://reactjs.org">React.JS</ALink> using <ALink url="https://reactrouter.com">react-rooter-dom</ALink>. All animations are made with css thanks to <ALink url="https://styled-components.com">styled-components</ALink>. Finally, the animated gradients are made with <ALink url="https://threejs.org">Three.js</ALink>.</p>
                    <p>It’s fully responsive and optimized. You can run it on a toaster.</p>
                </ParagraphCustom>
                <Nav>
                    Scroll or Click on a topic
                    <ButtonContainer>
                        <DarkButton onClick={() => this.gradientRef.current.scrollIntoView({behavior: "smooth", block: "center"})}>Fluid Gradiant with Three.js</DarkButton>
                        <DarkButton onClick={() => this.randomRef.current.scrollIntoView({behavior: "smooth", block: "center"})}>Character randomizer animation</DarkButton>
                        <DarkButton onClick={() => this.translateRef.current.scrollIntoView({behavior: "smooth", block: "center"})}>Random translate animation</DarkButton>
                    </ButtonContainer>
                </Nav>
                <LineContainer>
                    <div  ref={this.gradientRef} ><BlobExemple /></div>
                    <Separator />
                    <div  ref={this.randomRef} ><RandomExemple /></div>
                    <Separator />
                    <div  ref={this.translateRef} ><TranslateExemple /></div>
                    <Separator />
                </LineContainer>
            </Container>
        );
    }
}

const BlobExemple = () => {
    const [blur, setBlur] = useState(true)
    const [Width, setWidth] = useState(window.innerWidth);

    const updateDimensions = () => {
        const width = window.innerWidth
        setWidth(width)
    }

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    });

    return ( Width > 970 ?
        <LineWithplan>
            <Line>
                <ParagraphCustom>
                    <p>Those gradients are made with <ALink url="https://reactjs.org">React.JS</ALink>. Each one has a size linked to specifications of the selected projects.<br />Purple -&gt; Design<br />Orange -&gt; Documentary &amp; UX<br />Green  -&gt; Developpement</p>
                    <p>They are made from very low poly Icospheres. Each frame a noise displacement allows them to move like blobs. Furthermore, to add randomness they move around the center of the universe &#40;x:0, y:0, z: 0&#41;.</p>
                    <p>To prevent perspective scale deformation a orthographic camera is used.</p>
                </ParagraphCustom>
                <ContainerDemo>
                    <BlobContainer>
                        <Blobs position="center" blur={blur}/>
                    </BlobContainer>
                    <DarkButton onClick={() => setBlur(!blur)}>Toggle Blur</DarkButton>
                </ContainerDemo>
            </Line>
            <Plan src="/images/ThreejsPlan.svg" alt="three js plan"/>
        </LineWithplan>
        :
        <Line>
            <ParagraphCustom>
                <p>Those gradients are made with <ALink url="https://reactjs.org">React.JS</ALink>. Each one has a size linked to specifications of the selected projects.<br />Purple -&gt; Design<br />Orange -&gt; Documentary &amp; UX<br />Green  -&gt; Developpement</p>
                <p>They are made from very low poly Icospheres. Each frame a noise displacement allows them to move like blobs. Furthermore, to add randomness they move around the center of the universe &#40;x:0, y:0, z: 0&#41;.</p>
                <p>To prevent perspective scale deformation a orthographic camera is used.</p>
            </ParagraphCustom>
            <Plan src="/images/ThreejsPlan.svg" alt="three js plan"/>
            <ContainerDemo>
                <BlobContainer>
                    <Blobs position="center" blur={blur}/>
                </BlobContainer>
                <DarkButton onClick={() => setBlur(!blur)}>Toggle Blur</DarkButton>
            </ContainerDemo>
        </Line>
    )
}

const RandomExemple = () => {
    const [text, setText] = useState('')
    const [Width, setWidth] = useState(window.innerWidth);

    const updateDimensions = () => {
        const width = window.innerWidth
        setWidth(width)
    }

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    });

    return ( Width > 970 ?
        <Line>
            <ContainerDemo>
                <RandomContainer>
                    <AnimRandomText speed={100} text={"CHARACTER" + text} />
                    <AnimRandomText speed={100} text={"RANDOMIZER" + text} />
                </RandomContainer>
                <DarkButton onClick={() => setText(text === " " ? "" : " ")}>Play Animation</DarkButton>
            </ContainerDemo>
            <ParagraphCustom>
                <p>{"Convert the string in an ASCII character array. Replace each element of the array by a [element] + [random int]. Only keep space (32 in ASCII). Each 50ms reload the array to change characters."}</p>
                <p>{"Each 1 ms increment an index to keep the set original character one by one."}</p>
                <p style={{whiteSpace: "pre"}}>{"const randomizeAnimation = () => {\n  let newArray = []\n  for(i < incr && i < ascii.length) {\n    newArray.push(ascii[i]);\n  };\n  for(i < ascii.length) {\n    if (ascii[i] === 32) {\n      newArray.push(ascii[i]);\n    }\n    else {\n      newArray.push(ascii[i] + getRand());\n     }\n  };}\n};"}</p>
            </ParagraphCustom>
        </Line>
        :
        <Line>
            <ParagraphCustom>
                <p>{"Convert the string in an ASCII character array. Replace each element of the array by a [element] + [random int]. Only keep space (32 in ASCII). Each 50ms reload the array to change characters."}</p>
                <p>{"Each 1 ms increment an index to keep the set original character one by one."}</p>
            </ParagraphCustom>
            <ContainerDemo>
                <RandomContainer>
                    <AnimRandomText speed={100} text={"CHARACTER" + text} />
                    <AnimRandomText speed={100} text={"RANDOMIZER" + text} />
                </RandomContainer>
                <DarkButton onClick={() => setText(text === " " ? "" : " ")}>Play Animation</DarkButton>
            </ContainerDemo>
        </Line>
    )
}

const TranslateExemple = () => {
    const [text, setText] = useState('')

    return (
        <Line>
            <ParagraphCustom>
                <p>{"For this animation all characters are separated and each one is contained in an overflow hidden div."}</p>
                <p style={{whiteSpace: "pre"}}>{"<String>\n    <LettreContainer>\n        <Letter>\n            A\n        </Letter>\n    </LettreContainer>\n</String>"}</p>
                <p>{"Letters have a random animation-delay, animation-speed &  translateX at the first keyframe (-100% or 100%)."}</p>
            </ParagraphCustom>
            <ContainerDemo>
                <TranslateContainer>
                    <AnimTranslateText text={"RANDOM"} />
                    <AnimTranslateText text={"TRANSLATE"} />
                    <AnimTranslateText text={"ANIMATION"} />
                </TranslateContainer>
                <DarkButton  onClick={() => setText(text === " " ? "" : " ")}>Play Animation</DarkButton>
            </ContainerDemo>
        </Line>
    )
}


const ALink = (props) => {

    return (
        <A onClick={() => window.open(props.url, '_blank').focus()}>{props.children}</A>
    )
}

export default HowPage;