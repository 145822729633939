import { useEffect, useState } from 'react';

function getRandomArbitraryInt(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}

function stringToASCIIArray(str) {
    let array = [];
    const textArray = str.split('');

    for(let i = 0; i < textArray.length; i++){
        array.push( textArray[i].charCodeAt(0));
    }

    return array;
}

const AnimRandomText = (props) => {
    const [text, setText] = useState(props.text);
    const [lastProps, setLastProps] = useState(null);

    useEffect(() => {

        if (props.text !== lastProps) {
            setLastProps(props.text)
            let incr = 0
            const asciiArray = stringToASCIIArray(props.text);
            const speed = props.speed ? props.speed : 5;
            const increment = props.increment ? props.increment : 1;

            const randomizeAnimation = () => {
                let newArray = []

                for(let i = 0; i < incr && i < asciiArray.length; i++) {
                    newArray.push(asciiArray[i]);
                };
                for(let i = incr; i < asciiArray.length; i++) {
                    if (asciiArray[i] === 32)
                    {
                        newArray.push(asciiArray[i]);
                    }
                    else {
                        newArray.push(asciiArray[i] + getRandomArbitraryInt(-20, 20));
                    }
                };
                setText(String.fromCharCode(...newArray))
                if (asciiArray.length <= incr) {
                    clearInterval(random)
                    clearInterval(reveal)
                }
            }

            const random = setInterval(randomizeAnimation, 50)
            const reveal = setInterval(() => incr += increment, speed);
        }
    }, [props.text, lastProps, props.speed, props.increment]);

    return (
      <p>
        {text}
      </p>
    );
  }

export default AnimRandomText;