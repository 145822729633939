import styled from "styled-components";
import { ButtonLink, Paragraph } from "../components/Styled";
import { AnimRandomText, Blobs } from "../components";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #F4F3F1;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

const Title = styled.div`
    font-style: italic;
    font-size: 20vw;
    font-weight: 600;
    line-height: normal;
    margin-top: 6.5vh;
    text-align: center;

    @media (max-width: 970px) {
        font-size: 25vw;
        margin-top: 0;
    }
`

const LogoContainer = styled.div`
    padding: 60px;
`

const Logo = styled.svg`
    min-width: 125px;
    min-height: 125px;

    @media (max-width: 970px) {
        min-width: 60px;
        min-height: 60px;
    }
`

const LinkCustom = styled(Link)`
    &:hover {
        text-decoration: underline;
    }
`

const Intro = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10vh;
    align-items: center;

    @media (max-width: 970px) {
        gap: 7vh;
    }
`

const HowImade = styled(Paragraph)`
    gap: 5px;
    text-align: end;
    padding: 90px;
    position: absolute;
    right: 0;
    bottom: 0;

    @media (max-width: 970px) {
        padding: 0;
        position: relative;
        padding-bottom: 60px;
        text-align: center;
    }
`

const BlobsContainer = styled.div `
    width: 100vw;
    height: 15vw;
    z-index: 1002;
    position: absolute;
    top: calc(6.5vh + 20.5vw);
    mix-blend-mode: darken;

    @media (max-width: 970px) {
        top: calc(180px + 7vh + 25.5vw);
    }
`

const BlobsBox = styled.div `
    height: 100vw;
    width: 15vw;
    transform-origin: -50% left;
    transform: rotate(90deg) translate(calc(-50vw + 50%), -42.5vw);
`

const ErrorPage = () => {
    const [Width, setWidth] = useState(window.innerWidth);

    const updateDimensions = () => {
        const width = window.innerWidth
        setWidth(width)
    }

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    });

    return ( Width > 970 ?
        <>
            <BlobsContainer >
                <BlobsBox>
                    <Blobs stats={{des: 75, mod: 35, dev: 50, order: 0}} />
                </BlobsBox>
            </BlobsContainer>
            <Container>
                <Intro>
                    <Title><AnimRandomText speed={100} text="404" /></Title>
                    <Paragraph style={{alignItems: "center"}}>
                        You seems lost, let me help you
                        <ButtonLink to={"/"}> Go back home </ButtonLink>
                    </Paragraph>
                </Intro>
                <LogoContainer>
                    <Logo viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M124.443 70.7366L112.041 24.3566C110.523 18.7021 106.101 14.2896 100.455 12.7861L54.3249 0.545538C48.7279 -0.941631 42.7556 0.659935 38.6435 4.76191L4.81664 38.4438C0.671911 42.5785 -0.959871 48.6089 0.557686 54.2634L12.9592 100.643C14.4768 106.298 18.8989 110.71 24.5449 112.214L70.659 124.454C76.256 125.942 82.2284 124.34 86.3405 120.238L120.167 86.5562C124.328 82.4215 125.96 76.3911 124.443 70.7366ZM109.463 80.0192L82.4242 72.8448C82.0489 72.7468 81.6736 72.6324 81.3146 72.5343L60.5093 93.3547C52.5463 101.33 39.6225 101.33 31.6594 93.3547C23.6964 85.3795 23.6964 72.4362 31.6594 64.4611L52.6278 43.4773C52.5789 43.2812 52.5136 43.1014 52.4647 42.9053L45.138 15.5316C43.5225 9.46855 49.0542 3.9121 55.1082 5.51366L101.222 17.7542C104.045 18.506 106.265 20.7122 107.015 23.5395L119.449 70.0012C121.065 76.0643 115.533 81.6207 109.463 80.0192Z" fill="#1F1F1F"/>
                        <path d="M100.897 24.0304C92.9339 16.0552 80.0102 16.0552 72.0471 24.0304L52.6289 43.4779C56.5778 57.4671 67.4292 68.4166 81.3157 72.5186L100.897 52.9076C108.86 44.9324 108.86 32.0055 100.897 24.0304Z" fill="#1F1F1F"/>
                    </Logo>
                </LogoContainer>
                <HowImade>
                    How I made this site ?
                    <div>{"--> "}<LinkCustom to="/how" style={{fontWeight: "500"}}>{"Click here"}</LinkCustom></div>
                </HowImade>
            </Container>
        </>
        :
        <>
            <BlobsContainer>
                <BlobsBox>
                    <Blobs stats={{des: 75, mod: 35, dev: 50, order: 0}} />
                </BlobsBox>
            </BlobsContainer>
            <Container>
                <Intro>
                    <Logo>
                        <svg viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M124.443 70.7366L112.041 24.3566C110.523 18.7021 106.101 14.2896 100.455 12.7861L54.3249 0.545538C48.7279 -0.941631 42.7556 0.659935 38.6435 4.76191L4.81664 38.4438C0.671911 42.5785 -0.959871 48.6089 0.557686 54.2634L12.9592 100.643C14.4768 106.298 18.8989 110.71 24.5449 112.214L70.659 124.454C76.256 125.942 82.2284 124.34 86.3405 120.238L120.167 86.5562C124.328 82.4215 125.96 76.3911 124.443 70.7366ZM109.463 80.0192L82.4242 72.8448C82.0489 72.7468 81.6736 72.6324 81.3146 72.5343L60.5093 93.3547C52.5463 101.33 39.6225 101.33 31.6594 93.3547C23.6964 85.3795 23.6964 72.4362 31.6594 64.4611L52.6278 43.4773C52.5789 43.2812 52.5136 43.1014 52.4647 42.9053L45.138 15.5316C43.5225 9.46855 49.0542 3.9121 55.1082 5.51366L101.222 17.7542C104.045 18.506 106.265 20.7122 107.015 23.5395L119.449 70.0012C121.065 76.0643 115.533 81.6207 109.463 80.0192Z" fill="#1F1F1F"/>
                            <path d="M100.897 24.0304C92.9339 16.0552 80.0102 16.0552 72.0471 24.0304L52.6289 43.4779C56.5778 57.4671 67.4292 68.4166 81.3157 72.5186L100.897 52.9076C108.86 44.9324 108.86 32.0055 100.897 24.0304Z" fill="#1F1F1F"/>
                        </svg>
                    </Logo>
                    <Title><AnimRandomText speed={100} text="404" /></Title>
                    <Paragraph style={{alignItems: "center"}}>
                        You seems lost, let me help you
                        <ButtonLink to={"/"}> Go back home </ButtonLink>
                    </Paragraph>
                </Intro>
                <HowImade>
                    How I made this site ?
                    <div>{"--> "}<LinkCustom to="/how" style={{fontWeight: "500"}}>{"Click here"}</LinkCustom>{" <--"}</div>
                </HowImade>
            </Container>
        </>
    );
}

export default ErrorPage;
