import './App.css';
import { Routes, Route } from "react-router-dom";
import { HomePage, AboutPage, ContactPage, ErrorPage, ProjectsPage, ProjectTemplate, HowPage } from '../pages';
import { Footer, Navbar } from '../components';
import Transition from './Transition';
import styled from 'styled-components';
import { useState } from 'react';

const Root = styled.div`
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    z-index: 1;
    background-color: ${props => props.darkmode ? '#1F1F1F' : '#F4F3F1'} ;
    color: ${props => props.darkmode ? '#F4F3F1' : '#1F1F1F'};
`

const App = () => {
    const [darkMode, setDarkMode] = useState(false);

    return (
    <Root darkmode={darkMode}>
        <Navbar darkmode={darkMode}/>
        <Transition>
          <Routes>
              <Route path="/" element={<HomePage/>} />
              <Route path="contact" element={<ContactPage/>} />
              <Route path="projects" element={<ProjectsPage/>} />
              <Route path="projects/:projectURL" element={<ProjectTemplate/>} />
              <Route path="about" element={<AboutPage/>} />
              <Route path="how" element={ <HowPage  onMount={() => setDarkMode(true)} onUnmount={() => setDarkMode(false)}/> }/>
              <Route path="*" element={<ErrorPage/>} />
          </Routes>
        </Transition>
        <Footer/>
    </Root>
  );
}

export default App;
