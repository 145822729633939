import styled from 'styled-components';

const Link = styled.button `
    display: flex;
    gap: 5px;
    align-items: center;
    font-style: italic;
    flex-wrap: wrap;
    &:hover {
        text-decoration: underline;
    }
`

const ExternalLink = (props) => {
    let fill = props.fill ? props.fill : "#1F1F1F"
    let height = props.height ? props.height : "15px";

    return (
        <Link onClick={() => window.open(props.url, '_blank').focus()}>
            <svg width={height} height={height} viewBox={"0 0 15 15"} xmlns="http://www.w3.org/2000/svg">
                <path d="M11.4773 9.70999L10.5935 8.82499L11.4773 7.94124C11.7696 7.65152 12.0017 7.30693 12.1605 6.92724C12.3192 6.54755 12.4014 6.14025 12.4023 5.72872C12.4032 5.31719 12.3228 4.90953 12.1657 4.52915C12.0086 4.14878 11.778 3.80317 11.487 3.51217C11.196 3.22117 10.8504 2.99052 10.47 2.83345C10.0896 2.67638 9.68197 2.59598 9.27044 2.59689C8.85891 2.59779 8.45161 2.67996 8.07192 2.8387C7.69223 2.99743 7.34764 3.2296 7.05792 3.52187L6.17417 4.40624L5.28979 3.52249L6.17479 2.63874C6.99531 1.81823 8.10816 1.35727 9.26854 1.35727C10.4289 1.35727 11.5418 1.81823 12.3623 2.63874C13.1828 3.45926 13.6438 4.57211 13.6438 5.73249C13.6438 6.89287 13.1828 8.00573 12.3623 8.82624L11.4779 9.70999H11.4773ZM9.70979 11.4775L8.82542 12.3612C8.00491 13.1818 6.89205 13.6427 5.73167 13.6427C4.57129 13.6427 3.45843 13.1818 2.63792 12.3612C1.81741 11.5407 1.35645 10.4279 1.35645 9.26749C1.35645 8.10711 1.81741 6.99426 2.63792 6.17374L3.52229 5.28999L4.40604 6.17499L3.52229 7.05874C3.23002 7.34846 2.99786 7.69306 2.83912 8.07274C2.68039 8.45243 2.59821 8.85973 2.59731 9.27126C2.59641 9.6828 2.6768 10.0905 2.83387 10.4708C2.99094 10.8512 3.2216 11.1968 3.5126 11.4878C3.80359 11.7788 4.1492 12.0095 4.52958 12.1665C4.90996 12.3236 5.31761 12.404 5.72915 12.4031C6.14068 12.4022 6.54798 12.32 6.92767 12.1613C7.30735 12.0026 7.65195 11.7704 7.94167 11.4781L8.82542 10.5944L9.70979 11.4781V11.4775ZM9.26729 4.84812L10.1517 5.73249L5.73229 10.1512L4.84792 9.26749L9.26729 4.84874V4.84812Z" fill={fill}/>
            </svg>
            {props.name}
        </Link>
    )
}

export default ExternalLink;