import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { Blobs, ProjectArray } from '../components';

const FilterSelector = styled.div `
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 100px;
    width: 100vw;
`

const BlobsContainer = styled.div `
    width: 100vw;
    height: 15vw;
`

const BlobsBox = styled.div `
    height: 100vw;
    width: 15vw;
    transform-origin: 50% right;
    transform: rotate(-90deg) translate(calc(50vw - 50%), 42.5vw);
`

const VerticalBarre = styled.div `
    width: 85%;
    height: 1px;
    background-color: #1F1F1F;
`

const GraduationContainer = styled.div `
    width: 85%;
    position: relative;
    overflow: hidden;
    height: 180px;
`

const Graduation = styled.button `
    position: absolute;
    top: 0;
    height: 120px;
    border-left: 1px solid #1F1F1F;
    display: flex;
    align-items: flex-end;
    padding: 5px 10px;
    font-style: italic;
    left: ${props => props.position};
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
    width: 100%;

    &.selected {
        height: 180px ;
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
    }

    &:hover {
        height: 140px;
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
        &.selected {
            height: 180px ;
            transition-duration: 0.3s;
            transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
        }
    }
`

const GraduationBox = styled.div `
    display: flex;
    align-items: center;
    gap: 10px;
`

const FiltersContainer = styled.div`
    margin-bottom: calc(250px + 20vw);
`

const FiltersButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 60px 15vw;
    flex-wrap: wrap;
    gap: 15px;
`

const ButtonFilter = styled.button`
    padding: 15px;
    border-radius: 30px;
    font-size: 15px;
    font-family: "IBM-Plex-Mono";
    font-weight: 600;
    font-style: italic;
    border: solid;
    border-width: 1px;

    opacity: 0.75;
    background-color: transparent;
    color: #1F1F1F;
    border-color: #1F1F1F;

    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
    display: flex;

    &.selected {
        opacity: 1;
        background-color: #1F1F1F;
        color: #F4F3F1;
        border-color: rgba(31, 31, 31, 0);
    }
`

const filters = [
    {tag: "3D",             pos: "7%",      stats: {des: 70, mod: 15, dev: 15, order: 0}},
    {tag: "Motion",         pos: "18%",     stats: {des: 70, mod: 30, dev: 15, order: 0}},
    {tag: "Branding",       pos: "29%",     stats: {des: 70, mod: 40, dev: 20, order: 0}},
    { tag: "Documentary", pos: "45.7%", stats: { des: 50, mod: 70, dev: 35, order: 0 } },
    {tag: "UX/UI",          pos: "68.3%",   stats: {des: 30, mod: 40, dev: 70, order: 0}},
    {tag: "Dev.",           pos: "77.8%",   stats: {des: 15, mod: 15, dev: 70, order: 0}},
    {tag: "Prototype",      pos: "86.5%",   stats: {des: 20, mod: 15, dev: 70, order: 0}}
]

const ProjectsPage = () => {
    const [selectedFilter, setSelectedFilter] = useState('');

    return (
    <>
        <Filters setFilter={setSelectedFilter} selectedFilter={selectedFilter}/>
        <ProjectArray filter={selectedFilter} interactionMode={0}/>
    </>
  );
}

const Filters = (props) => {
    const [Width, setWidth] = useState(window.innerWidth);

    const updateDimensions = () => {
        const width = window.innerWidth
        setWidth(width)
    }

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    });

    return (Width > 970 ?
        <FiltersLine setFilter={props.setFilter} selectedFilter={props.selectedFilter}/>
        :
        <FiltersButton setFilter={props.setFilter} selectedFilter={props.selectedFilter}/>
    )
}

const FiltersButton = (props) => {
    return (
        <FiltersButtonContainer>
            {filters.map((filter, index) => {
                return (
                    <ButtonFilter position={filter.pos}
                                key={index} onClick={() => props.setFilter(filter.tag === props.selectedFilter ? '' : filter.tag)}
                                className={filter.tag === props.selectedFilter ? "selected" : ""}>
                        {filter.tag}
                    </ButtonFilter>
                )
                })}
        </FiltersButtonContainer>
    )
}

const FiltersLine = (props) => {
    const [blobStats, setBlobStats] = useState({des: 50, mod: 30, dev: 35, order: 0});
    return (
        <FiltersContainer>
        <FilterSelector>
            <BlobsContainer>
                <BlobsBox>
                    <Blobs stats={blobStats} />
                </BlobsBox>
            </BlobsContainer>
            <VerticalBarre/>
            <GraduationContainer>
            {filters.map((filter, index) =>
                    <Graduation position={filter.pos}
                                key={index} onClick={() => props.setFilter(filter.tag === props.selectedFilter ? '' : filter.tag)}
                                className={props.selectedFilter === filter.tag ? "selected" : ""}
                                onMouseEnter={() => setBlobStats(filter.stats)}
                                onMouseLeave={() => setBlobStats({des: 50, mod: 30, dev: 35, order: 0})}>
                        <GraduationBox>
                            {filter.tag}
                        </GraduationBox>
                    </Graduation>
            )}
            </GraduationContainer>
        </FilterSelector>
        </FiltersContainer>
    )
}

export default ProjectsPage;
