
import React, { Component } from "react";
import styled from 'styled-components';
import * as THREE from "three";
import Perlin3 from "./perlin.js";
import { BlurPass, EffectComposer, RenderPass } from "postprocessing";


const Container = styled.div`
    height: 100%;
    width: 100%;
    pointer-events: none;
    position: relative;
    overflow: hidden;
`

const ConstainerInner = styled.div`
    height: 100%;
    width:  ${props => props.position === "center" ? '100%' : "100vw"};;
    pointer-events: none;
    position: relative;
    margin-left: ${props => props.position === "center" ? "0" : "-50vw"};
    transform: ${props => props.position === "center" ? "scale(5, 2)" : ""};
`

function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

function lerp(x, y, a) {
    return ((1 - a) * x + a * y)
}

class Blobs extends Component {
    constructor(props) {
        super(props);

        this.canvasRef = React.createRef();
        this.containerRef = React.createRef();
        this.eventListener = null;
        this.scene = null;
        this.camera = null;
        this.renderer = null;
        this.composer = null;
        this.blobsRot = [
            {purpleRot: 0, orangeRot: 0, greenRot: 0},
            {purpleRot: Math.PI, orangeRot: Math.PI, greenRot: Math.PI}
        ]

        const scalePurple = () => {return (this.props.stats !== null ? this.props.stats.des : 1)};
        const scaleOrange = () => {return (this.props.stats !== null ? this.props.stats.mod : 1)};
        const scaleGreen = () => {return (this.props.stats !== null ? this.props.stats.dev : 1)};

        const updateBlobEffect = (mesh) => {
            var time = performance.now() * 0.0002 + mesh.id;
            var normalSize = 1;
            const position = mesh.geometry.attributes.position;
            let newPositionAttribute = []

            for (var i = 0; i < position.count; i++) {
                const vector = new THREE.Vector3();
                vector.fromBufferAttribute( position, i );
                vector.normalize().multiplyScalar(mesh.geometry.parameters.radius + mesh.geometry.parameters.radius * 0.75 * Perlin3(vector.x * normalSize + time, vector.y * normalSize, vector.z * normalSize));
                newPositionAttribute.push(vector.x, vector.y, vector.z);
            }
            mesh.geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( newPositionAttribute, 3 ) );
            mesh.geometry.computeVertexNormals();
            mesh.geometry.verticesNeedUpdate = true;
            mesh.rotation.y += getRandomArbitrary( 0.0001, 0.02);
        }

        const animationRandomMoove = (group, maxValue) => {
            let rand = getRandomArbitrary( 0.0001, maxValue);
            group.pivot.rotation.x += rand;
            group.sphere.rotation.x += -rand / 2;

            group.pivot.rotation.x = group.pivot.rotation.x % (Math.PI * 2);
            group.sphere.rotation.x = group.sphere.rotation.x % (Math.PI * 2);
        }

        const animationRepositioning = (group, rotPivot, rotSphere) => {
            group.pivot.rotation.x =  lerp(group.pivot.rotation.x, rotPivot, 0.05);
            group.sphere.rotation.x = lerp(group.sphere.rotation.x, rotSphere, 0.05);
        }

        const animationRescale = (mesh, scale, scaler, timer) => {
            mesh.scale.x = lerp(mesh.scale.x, scale * scaler.x, timer);
            mesh.scale.y = lerp(mesh.scale.y, scale * scaler.y, timer);
            mesh.scale.z = lerp(mesh.scale.z, scale * scaler.z, timer);
        }

        this.animate = (purpleSphere, orangeSphere, greenSphere) => {
            updateBlobEffect(purpleSphere.sphere)
            updateBlobEffect(orangeSphere.sphere)
            updateBlobEffect(greenSphere.sphere)
            if (this.props.stats) {
                animationRepositioning(purpleSphere, this.blobsRot[this.props.stats.order].purpleRot, -this.blobsRot[this.props.stats.order].purpleRot)
                animationRepositioning(orangeSphere, this.blobsRot[this.props.stats.order].orangeRot, -this.blobsRot[this.props.stats.order].purpleRot)
                animationRepositioning(greenSphere, this.blobsRot[this.props.stats.order].greenRot, -this.blobsRot[this.props.stats.order].purpleRot)
                animationRescale(purpleSphere.sphere, scalePurple(), {x: 0.007, y: 0.045, z:0.007}, 0.05)
                animationRescale(orangeSphere.sphere, scaleOrange(), {x: 0.007, y: 0.045, z:0.007}, 0.05)
                animationRescale(greenSphere.sphere, scaleGreen(), {x: 0.007, y: 0.045, z:0.007}, 0.05)
            }
            else {
                animationRandomMoove(purpleSphere, 0.003)
                animationRandomMoove(orangeSphere, -0.007)
                animationRandomMoove(greenSphere, -0.005)
                animationRescale(purpleSphere.sphere, 75, {x: 0.005, y: 0.05, z:0.005}, 0.01)
                animationRescale(orangeSphere.sphere, 45, {x: 0.005, y: 0.05, z:0.005}, 0.01)
                animationRescale(greenSphere.sphere, 55, {x: 0.005, y: 0.05, z:0.005}, 0.01)
            }
            if (this.props.blur === undefined || this.props.blur) {
                this.composer.render(this.scene, this.camera);
            } else {
                this.renderer.render(this.scene, this.camera);
            }
            requestAnimationFrame(() => this.animate(purpleSphere, orangeSphere, greenSphere) );
        }

        this.onResize = ()  => {
            const container = this.containerRef.current;
            this.renderer.setSize(container.clientWidth, container.clientHeight);
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.render(this.scene, this.camera);
        };
    }

    componentDidMount() {
        const canvas = this.canvasRef.current;
        const container = this.containerRef.current;
        const containerSize = {x: container.clientWidth, y: container.clientHeight}

        //setup
        this.scene = new THREE.Scene();
        this.camera = new THREE.OrthographicCamera(-743.4, 743.4, -791.4, 791.4, -1000, 1000);
        this.renderer = new THREE.WebGLRenderer({
            canvas: canvas,
            antialias: false,
            alpha: true,
            powerPreference: "high-performance",
            depth: false,
            stencil: false,
        });
        this.composer = new EffectComposer(this.renderer);
        this.composer.addPass(new RenderPass(this.scene, this.camera));
        var blurPass = new BlurPass({height: 240})
        blurPass.setKernelSize(5);
        this.composer.addPass( blurPass);

        this.camera.position.z = 1;

        // size & resize
        this.renderer.setSize(containerSize.x, containerSize.y);
        window.addEventListener('resize', this.onResize);

        // spheres & groupe
        const createIcosphere = (scale, color, position) => {
            var pivot = new THREE.Object3D();
            var geometry = new THREE.IcosahedronGeometry(100, 3);
            var material = new THREE.MeshBasicMaterial( { color: color } );
            var sphere = new THREE.Mesh( geometry, material );
            pivot.add( sphere );
            this.scene.add( pivot );

            sphere.position.set(position.x , position.y, position.z)
            sphere.scale.set(scale *  0.005, scale *  0.05, scale * 0.005)
            sphere.rotateY(getRandomArbitrary(0, (Math.PI * 2)))
            pivot.rotateX(getRandomArbitrary(0, (Math.PI * 2)))
            return ({pivot: pivot, sphere: sphere});
        }

        var purpleSphere = createIcosphere(75, "#4301EA", {x: -10, y: -300, z: -200})
        var orangeSphere = createIcosphere(45, "#FF5C00", {x: -10, y: -50, z: 100})
        var greenSphere = createIcosphere(55, "#8FFF00", {x: -10, y: 300, z: 200})

        this.animate(purpleSphere, orangeSphere, greenSphere)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize)
    }

    render() {
        return (
        <Container>
            <ConstainerInner ref={this.containerRef} position={this.props.position}>
                <canvas ref={this.canvasRef}/>
            </ConstainerInner>
        </Container>
    )};
}



export default Blobs;