import { AnimRandomText, AnimTranslateText } from '../components';
import styled from 'styled-components';
import {
    Paragraph,
} from '../components/Styled';


const Intro = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 22vh;
    margin-bottom: 20vh;
    padding: 0 8.5vw;

    @media (min-width: 2000px) {
        padding: 0 12vw;
    }

    @media (max-width: 970px){
        flex-direction: column;
        gap: 60px;
        margin-top: 90px;
        margin-bottom: 90px;
    }
`

const CTA = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.5vw;
    font-style: normal;
    font-size: 5vw;
    font-family: "Grafier-Dipslay";
    font-variation-settings: "wght" 655, "cnst" 100;
    line-height: 100%;
    font-weight: normal;

    @media (max-width: 970px) {
        font-size: 10vw;
        align-items: center;
        gap: 1vw;
    }
`

const ShortPragraph = styled(Paragraph)`
    max-width: 400px;
    width: 40vw;

    @media (max-width: 970px), (pointer: coarse) {
        width: 70vw;
    }
`

const List = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 8.5vw;
    gap: 15px;

    @media (min-width: 2000px) {
        padding: 0 12vw;
    }

    @media (max-width: 970px), (pointer: coarse) {
        padding: 30px 30px;
        gap: 30px;
    }
`

const ContactText = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;

    @media (max-width: 970px) {
        font-size: 11px;
        gap: 2px
    }
`

const Graphier = styled.div`
    font-size: 5vw;
    font-family: "Grafier-Dipslay";
    font-variation-settings: "wght" 655, "cnst" 100;
    line-height: 100%;
    font-weight: normal;
    overflow: hidden;

    @media (max-width: 970px) {
        font-size: 8vw;
    }
`

const PPContainer = styled.div`
    width: calc(5vw + 36px);
    height: calc(5vw + 36px);
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: center;
`

const PP = styled.img`
    height: 100%;
    transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    transition-duration: 500ms;
    transform: translateY(100%);

    @media (max-width: 970px), (pointer: coarse) {
        transform: translateY(0%);
    }
`

const Underline = styled.div`
    height: 2px;
    width: 100%;
    margin-top: -2px;
    background-color: #1F1F1F;
    transform: translateX(-100%);
    transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    transition-duration: 1000ms;

    @media (max-width: 970px), (pointer: coarse) {
        transform: translateX(0%);
    }
`

const Background = styled.div`
    position: absolute;
    transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    transition-duration: 500ms;
    background-image: url(/images/Grid.svg);
    background-repeat: repeat;
    width: 100vw;
    height: calc(5vw + 36px);
    left: 0;
    opacity: 0;

    @media (max-width: 970px), (pointer: coarse) {
        opacity: 1;
    }
`

const ContactBox = styled.a`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    transition-duration: 500ms;
    font-style: normal;
    font-weight: 500;
    &:hover {
        padding-left: 5vw;
        ${PP} {
            transform: translateY(0%);
        }
        ${Underline} {
            transform: translateX(0%);
        }
        ${Background} {
            opacity: 1;
        }
    }
`

const ContactPage = () => {
  return (
    <>
        <Intro>
            <CTA>
                <AnimTranslateText text="LET'S START" />
                <AnimTranslateText text="A PROJECT" />
                <AnimTranslateText text="TOGETHER" />
            </CTA>
            <ShortPragraph>
                <AnimRandomText speed={10} text="I am available as a freelance and will be happy to collaborate with you. I am also open to any worldwide cool opportunity."/>
            </ShortPragraph>
        </Intro>
        <List>
            <Contact text="Project inquiries" name="E-MAIL" image="/images/PP_Black.svg" url='mailto:kubeandpill@gmail.com'/>
            <Contact text="Work experience &amp; education" name="LINKEDIN" image="/images/PP_Orange.svg" url='https://www.linkedin.com/in/quentin-jeanningros/'/>
            <Contact text="Detailed case studies" name="BEHANCE" image="/images/PP_Purple.svg" url='https://www.behance.net/quentinjeanningros'/>
            <Contact text="Design &amp; Art Experimentation" name="INSTAGRAM" image="/images/PP_Purple.svg" url='https://www.instagram.com/quentinjeanningros/'/>
            <Contact text="Prototype and project development" name="GITHUB" image="/images/PP_Green.svg" url='https://github.com/quentinjeanningros'/>
        </List>
    </>
  );
}

const Contact = (props) => {
    return (
        <ContactBox href={props.url} target='_blank'>
            <ContactText>
                {props.text}
                <Graphier>
                    {props.name}
                    <Underline/>
                </Graphier>
            </ContactText>
            <PPContainer><PP src={props.image} atl={props.name + " image"} /></PPContainer>
            <Background />
        </ContactBox>
    )
}

export default ContactPage;
