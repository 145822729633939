import { Blobs, ProjectArray, AnimRandomText, AnimTranslateText } from '../components';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    PageSplitContainer,
    PageSplitPart,
    Marge,
    Paragraph
} from '../components/Styled';

const TitleGrafier = styled.div `
    font-family: "Grafier-Dipslay";
    font-variation-settings: "wght" 655, "cnst" 100;
    line-height: 100%;
    font-size: 6.75vw;
    text-align: start;
    width: 47vw;
    font-weight: normal;

    @media (max-width: 970px)  {
        width: auto;
        font-size: 13vw;
        text-align: center;
    }

    @media (max-width: 550px)  {
        font-size: 16vw;
    }
`

const TitleMono = styled.div `
    font-family: "Supply Mono";
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    font-size: 7.2vw;
    letter-spacing: -1%;
    text-align: end;
    width: 47vw;
    margin-bottom: -1vw;

    @media (max-width: 970px) {
        margin-top: -1vw;
        width: auto;
        font-size: 14vw;
        text-align: center;
    }

    @media (max-width: 550px) {
        font-size: 17vw;
    }
`

const Intro = styled(PageSplitContainer) `
    margin-top: 10vw;
    margin-bottom: 11vw;
    justify-content: center;
    align-items: center;
    background-image: url(/images/Grid.svg);
    background-repeat: repeat;

    @media (max-width: 970px) {
        margin-top: 16vh;
        margin-bottom: 16vh;
        background-image: none;
        gap: 60px
    }
`

const ParagraphIntro = styled(Paragraph) `
    width: 55%;
    max-width: 500px;
    @media (max-width: 970px) {
        width: auto;
        padding: 0px 15px;
    }
`

const BlobsContainer = styled.div `
    top: 0px;
    width: 500px;
    position: fixed;
    height: 100vh;
    left: 50vw;
    z-index: 5;
    pointer-events: none;
    mix-blend-mode: darken;
`

const InfoContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 90px;
    background-image: url(/images/Grid.svg);
    background-repeat: repeat-x;
    padding: 15px;
    color: #1F1F1F;
    font-size: 15px;
    font-family: "IBM-Plex-Mono";
    font-weight: 600;
    font-style: italic;
`

const HomePage = () => {
    return (
        <>
        <Intro>
            <PageSplitPart>
                <TitleGrafier>
                    <AnimTranslateText text="DESIGNER" />
                </TitleGrafier>
                <TitleMono>
                    <AnimRandomText speed={100} text="DEVELOPER" />
                </TitleMono>
            </PageSplitPart>
            <PageSplitPart>
                <ParagraphIntro>
                        <p>I'm a 3D motion, branding, and coding enthusiast. More than just looking good, my projects are filled with the soul of my clients.</p>
                        <p>My broad skill set helps me to solve problems using design, code, and motion, and to collaborate with other creative fields.</p>
                </ParagraphIntro>
            </PageSplitPart>
        </Intro>
        <HomeProjects />
    </>
     );
}

const HomeProjects = () => {
    const [blobStats, setBlobStats] = useState(null);
    const [Width, setWidth] = useState(window.innerWidth);

    const updateDimensions = () => {
        const width = window.innerWidth
        setWidth(width)
    }

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    });



    return ( Width > 970 ?
        <>
            <BlobsContainer>
                <Blobs stats={blobStats} />
            </BlobsContainer>
            <Marge/>
            <ProjectArray enterImage={setBlobStats} leaveImage={() => {setBlobStats(null)}} interactionMode={1}/>
        </>
        :
        <>
            <InfoContainer>Scroll &amp; Click</InfoContainer>
            <ProjectArray/>
        </>
    )

}

export default HomePage;