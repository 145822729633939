import styled from 'styled-components';
import React, { Component } from "react";
import config from '../config';
import { Link } from "react-router-dom";
import {
    PageSplitContainer,
    PageSplitPart,
    ProjectTitle,
    ProjectInfo,
    ProjectDate
} from '../components/Styled';

const ProjectContainer = styled.div `
    display: flex;
    flex-direction: column;
    cursor: none;

    @media (max-width: 970px) {
        gap: 60px;
        cursor: pointer;
    }
`

const CursorHover = styled.div `
    position: absolute;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    white-space: nowrap;

    font-style: italic;
    font-weight: 600;
    font-size: 15px;

    height: 55px;
    width: 55px;
    border-radius: 30px;

    color: rgba(0,0,0,0);
    opacity: 0;
    padding: 0px 0px;

    transition-property: opacity;
    transition-duration:  0.5s;

    &.text {
        color: #F4F3F1;
        background-color: #1F1F1F;
        padding: 0px 15px;
        opacity: 1;
        width: auto;
        mix-blend-mode: normal;
        transition-property: padding, opacity, width, translate;
        transition-delay: cubic-bezier(0.25, 1, 0.5, 1);
        transition-duration:  0.5s;
        transform: translate(-25%, -50%);
    }

    &.round {
        color: rgba(0,0,0,0);
        background-color: #F4F3F1;
        padding: 0px 0px;
        opacity: 1;
        width: 55px;
        mix-blend-mode: difference;
        transition-property: padding, opacity, width, translate;
        transition-delay: cubic-bezier(0.25, 1, 0.5, 1);
        transition-duration:  0.5s;
        transform: translate(-50%, -50%);
    }

    @media (max-width: 970px), (pointer: coarse) {
        display: none;
    }
`

const ProjectBox = styled(Link) `
    width: 100%;
    height: 100%;
    cursor: none;

    @media (max-width: 970px) {
        cursor: pointer;
    }
`

const ProjectBackground = styled.div`
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 450px;
    margin: 15px 0px;
    background-image: url(/images/Grid.svg);
    background-repeat: repeat;
    pointer-events: none;
    opacity: 0;

    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);

    &.show {
        opacity: 1;
        transition-timing-function: cubic-bezier(0.64, 0, 0.78, 0);
    }

    @media (max-width: 970px), (pointer: coarse) {
        display: none;
    }
`

const Project = styled(PageSplitContainer) `
    width: 100%;
    align-items: center;

    @media (max-width: 970px) {
        flex-direction: column;
    }
`

const PageSplitPartImage = styled(PageSplitPart)`
    height: 100%;
    cursor: none;
    z-index: 1;

    @media (max-width: 970px) {
        cursor: pointer;
    }
`

const ProjectImageContainer = styled.div `
    height: 450px;
    width: 47vw;
    margin: 15px 0px;
    overflow: hidden;

    @media (max-width: 970px) {
        width: 100vw;
        height: 210px;
        margin: 0px;
    }
`

const ProjectImage = styled.img `
    height: 450px;
    width: 47vw;
    object-fit: cover;
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);

    &.hovered {
        transform: scale(1.07, 1.07);
    }

    @media (max-width: 970px) {
        width: 100vw;
        height: 210px;
    }
`

const InfoContainer = styled.div `
    height: 450px;
    width: 40vw;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 15px 0px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);

    &.show {
        opacity: 1;
        transition-timing-function: cubic-bezier(0.64, 0, 0.78, 0);
    }

    @media (max-width: 970px), (pointer: coarse) {
        width: calc(100% - 30px);
        height: auto;
        gap: 7px;
        margin: 0px;
        padding: 15px;
    }
`

const PageSplitPartInfo = styled(PageSplitPart)`
    @media (max-width: 970px) {
        height: auto;
        border-top: 1px solid #1F1F1F;
    }
`

const Error = styled.div`
    display: flex;
    justify-content: center;
    font-style: italic;
    padding: 30px 15px 0px 15px;
`

class ProjectArray extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cursorClass: "none",
            hoverID: null,
            filter: '',
            width: window.innerWidth,
            isCursor: matchMedia('(pointer:fine)').matches
        };
        this.mouseX = window.innerWidth / 4;
        this.mouseY = window.innerHeight / 2;
        this.clientY = this.mouseY;
        this.lastScrolled = 0;
        this.wheelListerner = null;
        this.mooveListerner = null;
        this.resizeListerner = null;

        this.handleWindowMouseMove = e => {
            this.mouseX = e.pageX;
            this.mouseY = e.pageY;
            document.getElementById("cursor-hover").style.left = e.pageX +'px';
            document.getElementById("cursor-hover").style.top = e.pageY +'px';
            this.clientY = e.clientY;
        }

        this.handleWindowScroll = e => {
            var doc = document.documentElement;
            var actualScroll = (window.scrollY || doc.scrollTop)  - (doc.clientTop || 0);
            this.mouseY -= this.lastScrolled - actualScroll;
            this.lastScrolled = actualScroll;
            var hoverID = this.state.hoverID;
            var cursorClass = this.state.cursorClass;

            const elements = document.elementsFromPoint(this.mouseX, this.clientY);
            var passI = false
            for(let i = 0; i < elements.length; i++) {
                const id = elements[i].id ? elements[i].id.split('/') : null
                if (id && id[0] === "container") {
                    passI = true
                    const projectID = parseInt(id[1])
                    hoverID = projectID;
                    var passN = false
                    for(let n = 0; n < elements.length; n++) {
                        if (elements[n].id === "image" ) {
                            passN = true
                            cursorClass = "round";
                            if (this.props.enterImage) {this.props.enterImage(config.projects[projectID].stats)}
                            break
                        }
                    }
                    if (passN === false) {
                        cursorClass = "text"
                        if (this.props.leaveImage) { this.props.leaveImage() }
                    };
                    break;
                }
            };
            if (passI === false) { cursorClass = "none" };

            document.getElementById("cursor-hover").style.top = this.mouseY + 'px';
            this.setState({
                hoverID: hoverID,
                cursorClass: cursorClass
            })
        }

        this.updateDimensions = () => {
            const newWidth = window.innerWidth;
            const isCursor = matchMedia('(pointer:fine)').matches
            this.setState({
                isCursor: isCursor,
                width: newWidth
            });
            if (isCursor === false) {
                this.mouseX = window.innerWidth / 4;
                this.mouseY = window.innerHeight / 2;
            }
        }

        this.array = () => {
            var array = []
            config.projects.forEach((project, index) => {
                if (!this.state.filter || project.filtres.includes(this.state.filter)) {
                    project.stats.order = index % 2;
                    array.push(
                        <ProjectBox id={"container/" + index} key={index} to={"/projects/" + project.url}
                            onFocus={() => {
                                this.setState({ hoverID: index });
                                if (this.props.enterImage) {
                                    this.props.enterImage(project.stats)
                                }
                            }}
                            onBlur={() => {
                                this.setState({ hoverID: null });
                                if (this.props.leaveImage) {
                                    this.props.leaveImage()
                                }
                            }}
                            onMouseEnter={() => {
                                if (this.props.interactionMode === 0) {
                                    this.setState({ hoverID: index });
                                }
                            }}
                            onMouseLeave={() => {
                                if (this.props.interactionMode === 0) {
                                    this.setState({ hoverID: null });
                                }
                            }}>
                            <ProjectBackground className={this.state.hoverID === index ? "show" : ""}/>
                            <Project>
                                <PageSplitPartImage
                                    id="image"
                                    onMouseEnter={() => {
                                        this.setState({ cursorClass: "round" });
                                        if (this.props.interactionMode !== 0) {
                                            this.setState({ hoverID: index });
                                        }
                                        if (this.props.enterImage) {
                                            this.props.enterImage(project.stats)
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        this.setState({ cursorClass: "none" });
                                        if (this.props.interactionMode !== 0) {
                                            this.setState({ hoverID: null });
                                        }
                                        if (this.props.leaveImage) {
                                            this.props.leaveImage()
                                        }
                                    }}>
                                    <ProjectImageContainer>
                                        <ProjectImage
                                            src={project.cover}
                                            className={this.state.isCursor && this.state.width > 970 && this.state.hoverID === index ? "hovered" : ""}
                                            alt={project.title + " project cover"}/>
                                    </ProjectImageContainer>
                                </PageSplitPartImage>
                                <PageSplitPartInfo
                                    onMouseEnter={() => this.setState({ cursorClass: "text" })}
                                    onMouseLeave={() => this.setState({ cursorClass: "none" })}>
                                    <InfoContainer className={this.state.isCursor === false || this.state.width <= 970 || this.props.interactionMode === 0 || this.state.hoverID === index ? "show" : ""}>
                                        <ProjectTitle>{project.title.join(' ')}</ProjectTitle>
                                        <ProjectInfo>
                                            <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{project.filtres.join(' - ')}</div>
                                            <ProjectDate>{project.date}</ProjectDate>
                                        </ProjectInfo>
                                    </InfoContainer>
                                </PageSplitPartInfo>
                            </Project>
                        </ProjectBox>
                    )
                }
            })
            return (array.length > 0 ? array : <Error> No project available with this filter</Error>)
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        window.addEventListener('scroll', this.handleWindowScroll);
        window.addEventListener('mousemove', this.handleWindowMouseMove);
    };

    componentDidUpdate(prevProps) {
        if (this.props.filter !== prevProps.filter) {
            this.setState({ filter : this.props.filter })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        window.removeEventListener('scroll', this.handleWindowScroll);
        window.removeEventListener('mousemove', this.handleWindowMouseMove);
    }

    render() {
        return (
        <>
            <CursorHover id="cursor-hover" className={this.state.cursorClass}>
                {this.props.interactionMode === 0 ? "take a look" : "<- hover image"}
            </CursorHover>

            <ProjectContainer displayCursor={this.props.interactionMode === 0}>
            {this.array()}
            </ProjectContainer>
        </>
        )};
}


export default ProjectArray;