import styled from 'styled-components';
import { AnimRandomText, AnimTranslateText } from '../components';
import { Link } from 'react-router-dom';
import {
    ImageEnter,
    Paragraph
} from '../components/Styled';

const Container = styled.div`
    padding: 9vw 10vw 40px 10vw;
    position: relative;
    z-index: 1;

    @media (max-width: 970px) {
        padding: 5vh 0 60px 0;
    }
`

const Nom = styled.div`
    font-family: "Grafier-Dipslay";
    font-variation-settings: "wght" 655, "cnst" 100;
    line-height: 100%;
    font-size: 11vw;
    font-weight: normal;

    @media (max-width: 970px) {
        font-size: 16vw;
        width: 100%;
        text-align: center;
    }
`

const ProfilPicContainer = styled.div`
    width: 25.6vw;
    height: 36vw;
    position: absolute;
    right: 10vw;
    top: 10.5vw;
    z-index: -1;
    overflow: hidden;
    display: flex;
    align-items: flex-end;

    @media (max-width: 970px) {
        width: 55.5vw;
        height: 80vw;
        top: auto;
        margin-top: -3.5vw;
        right: 9.3vw;
    }
`

const ProfilPic = styled.img`
    object-fit: cover;
    width: 25.6vw;
    animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    animation-fill-mode: forwards;
    animation-name: ${ImageEnter    };
    animation-duration: 1000ms;

    @media (max-width: 970px) {
        width: 55.5vw;
    }
`

const ShortPragraph = styled(Paragraph)`
    max-width: 400px;
    width: 40vw;
    text-align: start;

    @media (max-width: 970px) {
        width: 60vw;
        max-width: 350px;
    }
`

const Intro = styled.div`
    padding-top: 10vh;
    padding-bottom: 75px;
    display: flex;
    flex-direction: column;
    gap: 10vh;

    @media (max-width: 970px) {
        padding-top: 85vw;
        gap: 30px;
    }
`

const AlignRight = styled.div`
    margin-right: calc(25.6vw + 30px);
    display: flex;
    flex-direction: column;
    align-items: end;

    @media (max-width: 970px) {
        margin-right: 15px;
    }
`

const FitParagraph = styled(Paragraph)`
    margin-right: 30vw;

    @media (max-width: 970px) {
        margin-right: 0px;
    }
`

const ServiceTitle = styled(Nom)`
    font-size: 5vw;
    overflow: hidden;

    @media (min-width: 2000px) {
        font-size: 3vw;
    }

    @media (max-width: 970px) {
        font-size: 40px;
        text-align: start;
        padding: 0 15px;
        width: auto;
    }
`

const ServicesContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 160px 0;
    gap: 90px;
    align-items: center;

    @media (min-width: 2000px) {
        padding: 160px 5vw;
    }

    @media (max-width: 970px) {
        padding: 60px 0;
    }
`

const ServiceParagraph = styled(Paragraph)`
    border-top: 1px solid #1F1F1F;
    padding: 15px 0;

    @media (max-width: 970px) {
        padding: 15px 0;
        margin: 0 15px;
    }
`

const ServiceBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 60%;
    max-width: 800px;

    @media (max-width: 970px) {
        max-width: auto;
        width: 100%;
    }
`

const Service = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.right {
        flex-direction: row-reverse;
        @media (max-width: 970px) {
            gap: 15px;
            flex-direction: column-reverse;
        }
    }
    &.left {
        flex-direction: row;
        @media (max-width: 970px) {
            gap: 15px;
            flex-direction: column-reverse;
        }
    }
`

const CTA = styled.div`
    gap: 160px;
    margin-left: -10vw;
    background-image: url(/images/Grid.svg);
    background-repeat: repeat;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    padding-bottom: 60px;

    @media (min-width: 2000px) {
        min-height: 15vw;
    }

    @media (max-width: 970px) {
        flex-direction: column;
        margin-left: 0;
        gap: 60px;
        padding: 30px;
        width: auto;
        margin-left: 0;
    }
`

const Underline = styled.div`
    height: 2px;
    width: 100%;
    margin-top: -2px;
    background-color: #1F1F1F;
    transform: translateX(100%);
    transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    transition-duration: 1000ms;

    @media (max-width: 970px), (pointer: coarse) {
        transform: translateX(0%);
    }
`

const CTAButtonContainer = styled(Link)`
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 0.5vw;
    font-style: normal;

    @media (min-width: 1800px) {
        gap: 0.2vw;
    }

    @media (pointer: coarse) {
        ${Underline} {
            transform: translateX(0%);
        }
    }

    @media (max-width: 970px) {
        align-items: center;
    }

    ${ServiceTitle} {
        transition-delay: cubic-bezier(0.25, 1, 0.5, 1);
        border-bottom: 1px solid rgba(31, 31, 31, 0);
        transition-duration:  0.3s;
    }

    &:hover,
    &:focus {
        ${Underline} {
            transform: translateX(0%);
        }
    }
`

const services = [
    {
        titles: ["DESIGN"],
        texts: ["Also call design kit, it’s a kind of design sytem automated with Figma. I often deliver an entier Figma file which allow you to generate a large amount of different design just by doing drag and drop.",
                "It’s an interesting tool for communication through different social media or platforms by folowing guidlines, in just a few clicks.",
                "It's possible to complete those kits with motion design templates made on After Effect, also called MOGRT which can speed up the work of video editor."]
    },
    {
        titles: ["(U)SER", "E(X)PERIENCE", "(I)NTERFACE"],
        texts: ["I believe that every design should have a functional and aesthetic purpose. As a UX researcher, I want to make my tools pleasant to use.",
                "I also do UX/UI for applications and websites, whether it's for ergonomics or entertainment."]
    },
    {
        titles: ["3D & MOTION"],
        texts: ["I’m a fan of photorealistic 3D render, I regularly use blender to make projects. I create my own hardsurface models and put a lot of details in them.",
                "I also do 2D motion and post production on After Effect. I often create motion design templates called MOGRT which can speed up the work of video editor."]
    },
    {
        titles: ["DEVELOPMENT"],
        texts: ["As a trained developer, I do front-end development. I have good knowledge in JS and Swift. I do a lot of React JS and CSS and sometimes I do Three.js if needed. I am regularly assisted by friends for admin sys. and backend development."]
    },
    {
        titles: ["BRANDING"],
        texts: ["The visual identity of your brand is important because it reflects your values. In my creative process I focus on making your ambitions and your audience reflect your vision."]
    }
]

const AboutPage = () => {
  return (
    <Container>
        <Nom><AnimTranslateText text="QUENTIN"/></Nom>
        <ProfilPicContainer>
            <ProfilPic  src="/images/ProfilPic.webp" alt="Picture of Quentin Jeanningros" />
        </ProfilPicContainer>
        <Intro>
            <ShortPragraph>
                  <AnimRandomText speed={10} text="Happy to see you here! I'm Quentin, a 3D motion, branding, and coding enthusiast." />
                  <AnimRandomText speed={10} text="My friends call me Grou_Grou_ and I am currently based in Strasbourg, France." />
            </ShortPragraph>
            <AlignRight>
                <ShortPragraph>
                      <AnimRandomText speed={10} text=" I’m all about crafting projects that not only make your brand stand out but also feel uniquely you." />
                </ShortPragraph>
            </AlignRight>
            <FitParagraph>
                  <AnimRandomText speed={10} text="More than just looking good, my projects are filled with the soul of my clients." />
                  <AnimRandomText speed={10} text=" My broad skill set helps me to solve problems using design, code, and motion, and to collaborate with other creative fields." />
            </FitParagraph>
        </Intro>
        <ServicesContainer>
            {services.map((service, index) =>
                <Service key={index} className={index % 2 === 0 ? "left" : "right"}>
                    <ServiceBox>
                        <div>
                            {service.titles.map((title, index) => <ServiceTitle key={index}> {title} </ServiceTitle>)}
                        </div>
                        <ServiceParagraph>
                            {service.texts.map((text, index) => <p key={index}> {text} </p>)}
                        </ServiceParagraph>
                    </ServiceBox>
                </Service>
            )}
        </ServicesContainer>
        <CTA>
            <CTAButton />
            <ShortPragraph>
                <p>I’m on all social media, feel free to pick which way of communication you want</p>
            </ShortPragraph>
        </CTA>
    </Container>
  );
}

const CTAButton = () => {
    return (
        <CTAButtonContainer to="/contact">
            <ServiceTitle>LET'S START <Underline style={{transitionDelay: "0ms"}}/> </ServiceTitle>
            <ServiceTitle>A PROJECT <Underline style={{transitionDelay: "100ms"}}/> </ServiceTitle>
            <ServiceTitle>TOGETHER <Underline style={{transitionDelay: "200ms"}}/> </ServiceTitle>
        </CTAButtonContainer>
    )
}


export default AboutPage;
