import styled, {keyframes} from 'styled-components'
import { Link } from "react-router-dom";

const ImageEnter = () => keyframes`
    0% {
        height: 0%;
    }
    100% {
        height: 100%;
    }
`

const PageSplitContainer = styled.div`
    display: flex;
    flex-direction: line;

    @media (max-width: 970px) {
        flex-direction: column;
    }
`

const PageSplitPart = styled.div`
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 970px) {
        width: 100vw;
    }
`

const Marge = styled.div`
    position: fixed;
    height: 100%;
    width: 1px;
    top: 0;
    left: 50vw;
    background-color: #1F1F1F;
    z-index: 10;
    pointer-events: none;
`

const Paragraph = styled.div`
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 15px;

    @media (max-width: 970px) {
        text-align: justify;
    }
`

const InfoBox = styled.div `
    padding: 15px;
    border-radius: 30px;
    background-color: #1F1F1F;
    color: #F4F3F1;
    font-size: 15px;
    font-family: "IBM-Plex-Mono";
    font-weight: 600;
    font-style: italic;
`

const Button = styled.button `
    padding: 15px;
    border-radius: 30px;
    font-size: 15px;
    font-family: "IBM-Plex-Mono";
    font-weight: 600;
    font-style: italic;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border: solid;
    border-width: 1px;
    user-select: none;

    opacity: 0.75;
    background-color: transparent;
    color: #1F1F1F;
    border-color: #1F1F1F;

    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);


    &:hover {
        opacity: 1;
        background-color: #1F1F1F;
        color: #F4F3F1;
        border-color: rgba(31, 31, 31, 0);
        transition-duration: 0.35s;
    }

    @media (max-width: 970px), (pointer: coarse) {
        opacity: 1;
        background-color: #1F1F1F;
        color: #F4F3F1;
        border-color: rgba(31, 31, 31, 0);
    }
`

const ButtonLink = styled(Link) `
    padding: 15px;
    border-radius: 30px;
    font-size: 15px;
    font-family: "IBM-Plex-Mono";
    font-weight: 600;
    font-style: italic;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border: solid;
    border-width: 1px;
    user-select: none;

    opacity: 0.75;
    background-color: transparent;
    color: #1F1F1F;
    border-color: #1F1F1F;

    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
    display: flex;
    gap: 7px;

    & > p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &:hover {
        opacity: 1;
        background-color: #1F1F1F;
        color: #F4F3F1;
        border-color: rgba(31, 31, 31, 0);
        transition-duration: 0.35s;
    }

    @media (max-width: 970px), (pointer: coarse) {
        opacity: 1;
        background-color: #1F1F1F;
        color: #F4F3F1;
        border-color: rgba(31, 31, 31, 0);
    }
`

const ProjectTitle = styled.div `
    font-family: "Grafier-Dipslay";
    font-style: normal;
    font-weight: normal;
    font-variation-settings: "wght" 655, "cnst" 100;
    line-height: 100%;
    font-size: 4vw;
    text-transform: uppercase;

    @media (max-width: 970px) {
        font-size: 48px;
    }

    @media (max-width: 550px) {
        font-size: 32px;
    }
`

const ProjectInfo = styled.div `
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 30px;
    font-weight: 500;
    font-style: normal;

    @media (max-width: 970px) {
        font-size: 12px;
    }
`

const ProjectDate = styled.div `
    font-family: "IBM-Plex-Mono";
    font-style: italic;
    font-weight: 400;
    text-align: end;
    white-space: nowrap;
`


export {
    PageSplitContainer,
    PageSplitPart,
    Marge,
    Paragraph,
    Button,
    InfoBox,
    ProjectTitle,
    ProjectInfo,
    ProjectDate,
    ButtonLink,
    ImageEnter
}
