import styled, {keyframes} from 'styled-components';
import { Link, useLocation } from "react-router-dom";
import AnimRandomText from './AnimRandomText';
import { useState } from 'react';
import {
    PageSplitContainer,
    PageSplitPart
} from './Styled';

const Roll = keyframes`
    0% {
        transform: translateY(0px);
    }
    1% {
        transform: translateY(-30px);
    }
    25% {
        transform: translateY(-30px);
    }
    26% {
        transform: translateY(-60px);
    }
    50% {
        transform: translateY(-60px);
    }
    51% {
        transform: translateY(-90px);
    }
    75% {
        transform: translateY(-90px);
    }
    76% {
        transform: translateY(-120px);
    }
    100% {
        transform: translateY(-120px);
    }
`

const IntroContainer = styled.div`
    width: 45vw;
    overflow: hidden;
    height: 30px;
    margin: 10px 0px;
`

const TextCarrousel = styled.div`
    height: 30px;
`

const HiddenContainer = styled(Link)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    animation-iteration-count: infinite;
    animation-fill-mode: none;

    &:hover {
        animation-delay: 1s;
        animation-duration: 5s;
        animation-name: ${Roll};
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-flow: column;
    z-index: 2;
    position: sticky;
    top: 0px;
    background-color: ${props => props.darkmode ? '#1F1F1F' : '#F4F3F1'} ;
    align-items: center;
`

const NavLinkContainer = styled.div `
    width: 62%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 970px) {
        width: auto;
        padding: 15px 0px;
    }
`

const NavLinkBox =  styled.div `
    white-space: nowrap;
    user-select: none;
    &.underline {
       border-bottom: 2px solid #1F1F1F;
    }

`

const NavLink = styled(Link) `
    padding: 10px;

    &:hover {
        cursor: pointer;
    }
`

const PageSplitPartIntro = styled(PageSplitPart)`
    @media (max-width: 970px) {
        display: none;
    }
`


const Nav = (props) => {
    const [text, setText] = useState('')
    return (
        <NavLink to={props.path}  onMouseEnter={() => setText(text === " " ? "" : " ")} >
            <NavLinkBox className={useLocation().pathname === props.path ? "underline" : ""} style={{width: props.width}}>
                <AnimRandomText speed={50} text={props.text + text} />
            </NavLinkBox>
        </NavLink>
    )
}

const Navbar = (props) => {
    return (
        <Container darkmode={props.darkmode}>
            <PageSplitContainer>
                <PageSplitPartIntro>
                    <IntroContainer>
                    <HiddenContainer to="/">
                        <TextCarrousel>Quentin Jeanningros</TextCarrousel>
                        <svg width="30" height="30" viewBox="0 0 759 759" fill={props.darkmode ? "#F4F3F1" : "#1F1F1F"} xmlns="http://www.w3.org/2000/svg">
                            <path d="M755.384 429.405L680.105 147.856C670.893 113.531 644.05 86.7447 609.778 77.6177L329.76 3.31168C295.785 -5.71615 259.532 4.00612 234.571 28.9071L29.2377 233.373C4.07859 258.472 -5.82655 295.079 3.38523 329.405L78.6643 610.954C87.8761 645.28 114.719 672.065 148.991 681.193L428.91 755.499C462.885 764.526 499.138 754.804 524.099 729.903L729.432 525.438C754.691 500.338 764.596 463.731 755.384 429.405ZM664.455 485.755L500.326 442.203C498.048 441.608 495.77 440.913 493.591 440.318L367.3 566.708C318.963 615.121 240.514 615.121 192.177 566.708C143.84 518.295 143.84 439.723 192.177 391.31L319.458 263.928C319.161 262.738 318.765 261.646 318.468 260.456L273.994 94.2844C264.188 57.4787 297.766 23.7483 334.514 33.4706L614.434 107.777C631.57 112.34 645.041 125.733 649.597 142.896L725.074 424.941C734.88 461.747 701.302 495.477 664.455 485.755Z"/>
                            <path d="M612.455 145.875C564.117 97.4624 485.669 97.4624 437.332 145.875L319.46 263.932C343.431 348.853 409.3 415.321 493.593 440.222L612.455 321.174C660.792 272.761 660.792 194.288 612.455 145.875Z"/>
                        </svg>
                        <TextCarrousel>Grou_Grou_</TextCarrousel>
                        <svg width="30" height="30" viewBox="0 0 759 759" fill={props.darkmode ? "#F4F3F1" : "#1F1F1F"} xmlns="http://www.w3.org/2000/svg">
                            <path d="M755.384 429.405L680.105 147.856C670.893 113.531 644.05 86.7447 609.778 77.6177L329.76 3.31168C295.785 -5.71615 259.532 4.00612 234.571 28.9071L29.2377 233.373C4.07859 258.472 -5.82655 295.079 3.38523 329.405L78.6643 610.954C87.8761 645.28 114.719 672.065 148.991 681.193L428.91 755.499C462.885 764.526 499.138 754.804 524.099 729.903L729.432 525.438C754.691 500.338 764.596 463.731 755.384 429.405ZM664.455 485.755L500.326 442.203C498.048 441.608 495.77 440.913 493.591 440.318L367.3 566.708C318.963 615.121 240.514 615.121 192.177 566.708C143.84 518.295 143.84 439.723 192.177 391.31L319.458 263.928C319.161 262.738 318.765 261.646 318.468 260.456L273.994 94.2844C264.188 57.4787 297.766 23.7483 334.514 33.4706L614.434 107.777C631.57 112.34 645.041 125.733 649.597 142.896L725.074 424.941C734.88 461.747 701.302 495.477 664.455 485.755Z"/>
                            <path d="M612.455 145.875C564.117 97.4624 485.669 97.4624 437.332 145.875L319.46 263.932C343.431 348.853 409.3 415.321 493.593 440.222L612.455 321.174C660.792 272.761 660.792 194.288 612.455 145.875Z"/>
                        </svg>
                        <TextCarrousel>Quentin Jeanningros</TextCarrousel>
                    </HiddenContainer>
                    </IntroContainer>
                </PageSplitPartIntro>
                <PageSplitPart>
                    <NavLinkContainer>
                        <Nav path="/" text="Home" width="43.21px"/>
                        <Nav path="/projects" text="Projects" width="86.4px"/>
                        <Nav path="/about" text="About" width="54px"/>
                        <Nav path="/contact" text="Contact" width="75.6px"/>
                    </NavLinkContainer>
                </PageSplitPart>
            </PageSplitContainer>
      </Container>
    );
}

export default Navbar;